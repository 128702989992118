<template>
  <div
    :class="[
      // projectInstance.active ? 'bg-white' : 'bg-red-100',
      calledToday ? 'bg-green-100' : 'bg-white',
    ]"
    class="w-full flex flex-wrap text-xs mb-1 border border-gray-100 p-4 rounded-lg"
  >
    <div v-if="!changeProject" class="w-full flex text-left">
      <div v-if="showCall" class="flex-shrink text-gray-800 font-bold  flex items-center">
        <div class="w-10">
          <input
            type="checkbox"
            name="check"
            id="check"
            @change="updateCall"
            v-model="called"
          />
        </div>
      </div>
      <div class="flex-shrink text-sm text-gray-800 font-bold  flex items-center">
        <div class="w-72 ">
          <h3><span class="font-size">Customer: {{ projectInstance.jobs[0].company }}</span></h3>
          <h2 class=" truncate">#{{ projectInstance.projectId }} - {{ projectInstance.projectName }}</h2>  <span @click="changeProject = true, newName = projectInstance.projectName" class="text-xs font-normal cursor-pointer text-primary hover:underline">Change </span>
        </div>
      </div>
      
      <div
        v-if="projectInstance.lastCalled"
        class="flex-shrink mx-10 items-center flex text-gray-800"
      >
        <span
          :class="calledToday ? 'bg-green-600' : 'bg-gray-400'"
          class="text-white px-4 py-1 rounded-full text-sm"
        >
          <span class="font-bold">Last Called: </span
          >{{
            $moment(projectInstance.lastCalled.seconds * 1000).format(
              "DD MMM YYYY - HH:mmA"
            )
          }}
        </span>
      </div>


      <div
        :class="showCall ? 'ml-10' : ''"
        class="flex-shrink mr-6 text-gray-800 font-bold border-l border-gray-200 ml-2 flex items-center"
      >
        <span class="font-bold whitespace-nowrap mr-2 ml-2">Site Contact: </span>{{ projectInstance.contact }}
      </div>
      <div class="pr-6 text-gray-800  flex items-center">
        <a
          :href="'tel:' + projectInstance.phone"
          class="text-primary cursor-pointer hover:text-secondary"
          ><i class="fa-solid fa-phone"></i> {{ projectInstance.phone }}</a
        >
      </div>
      <!-- <div class="flex-shrink text-gray-800 mr-10 flex items-center">
       <div class="w-40">
        <h3 class="truncate">
          <a
          :href="'mailto:' + project.email"
          class="text-primary cursor-pointer hover:text-secondary"
          ><i class="fa-solid fa-at"></i> {{ project.email }}</a
        >
        </h3>
       </div>
      </div> -->
      
      <div class="flex-grow justify-end items-center flex text-gray-800">
        <router-link
          :to="{ name: 'Jobs', query: { jobId: projectInstance.jobs[0].id } }"
          class="text-white bg-primary hover:bg-secondary px-3 py-2 rounded cursor-pointer text-sm"
          >View</router-link
        >
        <!-- <span v-if="showJobs" @click="showJobs = false"
                class="text-gray-500 bg-gray-200 hover:bg-secondary px-3 py-2 rounded cursor-pointer text-sm">Hide Jobs</span>
                </div> -->
      </div>
    </div>

    <div v-if="changeProject" class="w-full bg-gray-100  py-4 flex text-left justify-center items-center h-full relative">
      <div @click="changeProject = false" class="absolute top-0 text-secondary cursor-pointer hover:text-primary right-0 m-4">
        <i class="fa-solid fa-xmark"></i>
      </div>

      <div class="flex-shrink">
        <input  type="text" v-model="newName" class="h-12 w-96 px-6 bg-white rounded-lg">
      </div>
      <div class="flex-shrink ml-2">
        <div @click="changeName()" class="h-12 rounded-lg text-sm bg-primary px-4 text-white cursor-pointer hover:bg-secondary flex items-center justify-center">Update Project Name</div>
      </div>
    </div>

    <div class="w-full border-t border-gray-100 mt-2 pt-2 flex">
      <div class="flex-grow items-center flex text-gray-800">
        <div class="w-56">
          <h3 class="whitespace-nowrap">
            <i class="fa-solid text-blue-600 fa-location-pin mr-1"></i>
        {{ projectInstance.projectAddress }}
          </h3>
        </div>
      </div>
        <div class="flex-shrink flex items-center">
          <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-xs mb-1"
              >
                Active
              </div>
              <select
                v-model="projectInstance.active"
                @change="changeActivity()"
                placeholder="active"
                class="rounded h-6 bg-gray-200 w-20 outline-none px-2 text-gray-600"
              >
                <option :value="true">Active</option>
                <option :value="false">Inactive</option>
              </select>
        </div>
   
    </div>


  </div>
</template>

<script>
import { bus } from "../../main";
import { setFire } from "../../firestore/utils";
export default {
  props: ["showCall", "project", "date"],
  data() {
    return {
      projectInstance: null,
      newName: '',
      changeProject: false,
      called: false,
      showJobs: false,
    };
  },
  computed: {
    calledToday() {
      if (this.projectInstance.lastCalled) {
        let a = false;
        if (
          this.$moment(this.projectInstance.lastCalled.seconds * 1000).format(
            "DD MMM YYYY"
          ) == this.$moment(this.date).format("DD MMM YYYY")
        ) {
          a = true;
        }
        return a;
      } else return false;
    },
  },
  methods: {
    changeName(){
      this.project.jobs.forEach(i => {
        try{
          setFire({
            collection: "jobs",
            doc: i.id,
            body: { projectName: this.newName },
          });
        } catch(e){
          console.log(e)
        }
        this.changeProject = false
      })
    },
    changeActivity(){
      this.project.jobs.forEach(i => {
        try{
          setFire({
            collection: "jobs",
            doc: i.id,
            body: { active: this.project.active },
          });
        } catch(e){
          console.log(e)
        }
      })

      setFire({
            collection: "projects",
            doc: JSON.stringify(this.project.projectId),
            body: { active: this.project.active },
          });
    },
    async updateCall(event) {
  for (let i = 0; i < this.projectInstance.jobs.length; i++) {
    let _date = new Date();
    let _lastCalled = event.target.checked ?  {
        seconds: Math.floor(_date.getTime() / 1000),
        nanoseconds: (_date.getTime() % 1000) * 1000000
      } : null;

    try {
      const response = await setFire({
        collection: "jobs",
        doc: this.projectInstance.jobs[i].id,
        body: { lastCalled: _lastCalled },
      });

      // Use Vue.set to ensure reactivity
      this.$set(this.projectInstance.jobs[i], 'lastCalled', _lastCalled);
      this.$set(this.projectInstance, 'lastCalled', _lastCalled);
    } catch (e) {
      console.log(e);
    }
  }
},

  },
  created() {
    this.projectInstance = this.project
    this.newName = this.project.projectName
    if (this.calledToday) this.called = true;
  },
};
</script>