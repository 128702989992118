import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/tailwind.css';
import * as VueGoogleMaps from 'vue2-google-maps'
import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
Vue.use(VueEasytable);
Vue.use(require('vue-moment'));

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAgzzzBwnKAnilSbETwkny6ApTz18jlHr4',
    libraries: 'places',
  },
  installComponents: true
})

export const bus = new Vue();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
