<template>
    <div class="relative h-full lg:mt-0 -mt-4 bg-gray-200 rounded-t-xl flex flex-wrap">
      <div class="w-56 hidden sm:block h-screen bg-secondary text-left p-4">

        <h2 class="text-white my-6">Filter Customers</h2>

<div class="mb-4">
        <label class="text-sm text-gray-400">Customers In Range</label>
        <input type="text" placeholder="100KM" class="bg-gray-900 w-full text-white rounded px-4 h-12">
        <input type="text" placeholder="Kingscliff" class="bg-gray-900 mt-2 w-full text-white rounded px-4 h-12">
        </div>

      </div>
      <div class="flex-grow">
      <div class="w-full flex h-20 space-x-10 border-b border-gray-200 items-center" v-for="(j, index) in customers" :key="index">
        <div class="flex-grow">
            <div class="w-40"><h2 class="truncate text-lg text-secondary font-bold">{{j.company}}</h2></div>
        </div>
        <div class="flex-grow">
            <div class="w-56">
                {{j.contact}}
            </div>
        </div>
        <div class="flex-grow">
            <div class="w-40">
                <a class="text-primary cursor-pointer" :href="'tel:'+j.phone">{{j.phone}}</a>
            </div>
        </div>
        <div class="flex-grow">
            <div class="w-40">
                <a class="text-primary cursor-pointer" :href="'mailto:'+j.email">{{j.email}}</a>
            </div>
        </div>
        <div class="flex-shrink">
            <div class="w-40 h-20 bg-primary hover:bg-secondary text-white rounded flex items-center justify-center">
                View Jobs
            </div>
        </div>
      </div>
      </div>
    </div>
</template>

<script>
import { bus } from '../../main'
export default {
  props:['date'],
  data(){
    return{
      customers: [],
    }
  },
  watch:{
    date(){
      this.getItems()
    }
  },
    methods:{
      showWorkCard(i){
            bus.$emit("showWorkCardFunc", i)
        },
      jobStyles(job){
        if(job.contractor && job.status == 'ON-CALL') return "bgyellowstripes text-white bg-yellow-600"
        if(job.contractor && job.status == 'FIRM') return "bggreenstripes text-white bg-green-600"
        if(job.contractor && job.status == 'CANCELLED') return " text-gray-600 bg-gray-100"
        if(!job.contractor) return "bgredstripes text-white bg-red-900"
      },
      async getItems(){
    },

  },
    created(){
      this.getItems()
    }
}
</script>
