import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: null,
    currentStartDate: new Date(),
    currentEndDate: new Date(),
  },
  mutations: {
    updateState(state, data){
      state[data.state] = data.body
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
