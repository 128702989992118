<template>
  <div style="z-index: 9999999"
    class="fixed z-40 top-0 left-0 overflow-y-scroll h-screen bg-white w-full"
  >
    <div class="w-full bg-gray-900 h-20 flex items-center px-0">
      <div class="flex-shrink">
        <div
          @click="closeModal()"
          class="bg-white bg-opacity-5 flex items-center justify-center h-12 w-12 rounded m-4"
        >
          <svg
            class="w-8 h-8 text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
      </div>
      <div class="flex-grow text-left relative">
        <h2 class="text-white header-bold">List clients</h2>
        <!-- <div class="absolute top-0 z-20 text-white right-0 mt-3 mr-2">
          <svg
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z"></path>
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          placeholder="Search for clients"
          class="w-full h-12 bg-gray-700 text-white outline-none px-2 rounded border-gray-500 border"
        /> -->
      </div>
      <div class="flex-shrink mx-2">
        <div
          class="h-12 w-12 flex items-center justify-center bg-primary rounded text-white"
        >
          <svg
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <div
      v-for="(c, index) in clients"
      :key="index"
      class="w-full flex border-b border-gray-200"
    >
      <div @click="showCustomersModal(c)" class="flex-grow items-center flex px-4">
        <div class="w-32 text-left">
          <span class="header-heavy tracking-tight text-base">
          {{ c.company }}
        </span>
        <div class="text-xs text-secondary">{{c.contact}}</div>
        </div>
      </div>

      <div class="flex-shrink h-full flex items-center text-left">
        <div class="w-56 h-full py-4 items-center flex flex-wrap">
          <div class="w-full">
            <h3 class="text-xs header-bold text-gray-600">Projects</h3>
          <div class="w-full text-sm text-primary" v-for="(j, index) in c.jobs" :key="index">
            <div class="w-full" @click="showProject(j, index)" v-if="j.active">
              {{ j.projectName }} <span class="text-xs text-gray-400">{{ j.project }}</span>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div
        @click="showCustomersModal(c)"
        class="flex-shrink items-center h-20 px-4 flex"
      >
        <svg
          class="w-6 h-6 text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../main";
import {getDocsOrderByCompany} from "../../firestore/utils"
export default {
  data() {
    return {
      clients: []
    };
  },
  methods: {
    async getItems(){
      this.clients = await getDocsOrderByCompany({collection: "customers"})
    },
    showProject(project, client) {
      const params = {
        editProjectsByProjectId: project.project,
        customerId: client.id,
        mode: "edit",
        projectName: project.projectName,
        address: project.address.street_number + " " + project.address.route,
      };
      
      this.$router.push({ name: "Home", query: params });
      bus.$emit("showAddProjectFormEvent")
    },
    closeModal() {
      bus.$emit("showCustomersCardFunc", false);
    },
    showCustomersModal(client) {
      bus.$emit("showCustomersModal", client);
    },
  },
  created(){
    this.getItems()
  }
};
</script>
