<template>
  <div class="overflow-y-hidden flex flex-col bg-white w-full h-screen">
    <div class="h-auto w-full bg-gray-900 relative">
      <div class="container mx-auto text-left flex items-center">
        <div class="flex-grow sm:ml-0 ml-20">
          <h2
            v-if="!editMode"
            class="text-white header-heavy tracking-tight text-base sm:text-xl mt-2"
          >
            Add New Job
          </h2>
          <h2
            v-else
            class="text-white header-heavy tracking-tight text-base sm:text-xl mt-2"
          >
            Edit Job
          </h2>
        </div>
        <div class="flex-shrink">
          <div
            v-if="!editMode"
            @click="submitForm()"
            class="bg-primary cursor-pointer hover:bg-secondary text-white rounded m-2 p-4 header-heavy"
          >
            Create Job
          </div>
          <div
            v-else
            @click="editForm()"
            class="bg-primary cursor-pointer hover:bg-secondary text-white rounded m-2 p-4 header-heavy"
          >
            Edit Job
          </div>
        </div>
      </div>
    </div>

    <div
      class="h-auto w-full overflow-y-scroll relative rounded-t-xl bg-gray-200"
    >
      <transition name="slide">
        <div class="fixed top-0 right-0 z-50 sm:w-96" v-if="createCustomer">
          <create-customer :customer="customer" />
        </div>
      </transition>
      <div class="container mx-auto p-2">
        <div class="grid grid-cols-12 sm:gap-20">
          <div
            class="col-span-12 sm:col-span-3 flex flex-wrap items-center justify-start"
          >
            <div
              class="hidden sm:block flex-shrink mr-3 text-left text-sm mb-1"
            >
              <div class="w-14">Client</div>
            </div>
            <select
              type="text"
              value="KCT"
              class="rounded flex-grow h-12 bg-white outline-none px-2 text-gray-600"
            >
              <option value="KCT">KCT</option>
            </select>
          </div>
          <div class="col-span-12 sm:col-span-8 h-full flex">
            <div class="w-2/3 text-left flex items-center">
              <div
                class="hidden sm:block flex-shrink mr-3 text-left text-sm mb-1"
              >
              <div class="w-14">Customer</div>
              </div>
              <div class="flex-grow relative">
                <multiselect
                  label="company"
                  v-if="!customer"
                  track-by="company"
                  @search-change="searchCustomers"
                  :loading="loadingSelect"
                  v-model="customer"
                  :options="customers"
                ></multiselect>

                <div
                  class="flex-shrink flex bg-gray-100 rounded px-2 py-2 text-sm w-96"
                  v-else
                >
                  <div class="flex w-full h-full items-center">
                    <span class="font-bold text-sm mr-2">{{
                      customer.company
                    }}</span>
                    -
                    <span class="ml-2 text-xs text-gray-400">{{
                      customer.contact
                    }}</span>
                    <!-- <div class="w-full flex text-xs">
                      <div class="flex-shrink font-bold">Contact Name: </div>
                      <div class="flex-shrink ml-2">{{ customer.contact }} </div>
                    </div>
                    <div class="w-full flex text-xs">
                      <div class="flex-shrink text-sm font-bold">Contact Phone: </div>
                      <div class="flex-shrink ml-2"> <a :href="'tel:' + customer.phone" class="text-primary cursor-pointer">{{ customer.phone }}</a> </div>
                    </div>
                    <div class="w-full flex text-xs">
                      <div class="flex-shrink font-bold">Contact Email: </div>
                      <div class="flex-shrink ml-2"> <a :href="'mailto:' + customer.email" class="text-primary cursor-pointer">{{ customer.email }}</a> </div>
                    </div> -->

                    <div class="flex-shrink ml-auto">
                      <span
                        @click="createCustomer = !createCustomer"
                        class="text-xs text-white rounded-full py-1 px-3 mx-2 bg-primary cursor-pointer hover:bg-secondary cursor-pointer"
                      >
                        Edit
                      </span>
                    </div>
                  </div>

                  <div
                    class="hidden sm:flex flex-shrink pt-1"
                    v-if="customer"
                    @click="customer = null"
                  >
                    <i
                      class="text-red-500 cursor-pointer fa-solid fa-xmark"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!customer" class="w-1/3 pl-4 flex items-center">
              <div
                @click="createCustomer = !createCustomer"
                class="rounded w-full bg-gray-900 cursor-pointer text-white h-12 px-4 items-center justify-center flex"
              >
                New
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full bg-gray-300" v-if="customer">
        <div class="container mx-auto p-2">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-4 h-full items-center flex">
              <div
                class="flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
              <div class="w-14">Project</div>
              </div>
              <div class="w-full relative">
                <select
                type="text" @change="selectProject"
                v-model="projectId"
                placeholder="Project Name"
                class="rounded h-12 bg-white w-full outline-none px-2 text-gray-600"
              >
                <option
                  :value="p"
                  v-for="(p, index) in projects"
                  :key="'pId' + index"
                >
                  #{{ p.projectId }} - {{ p.projectName }}
                </option>
              </select>
              </div>
            </div>

            <!-- <div class="col-span-12 sm:col-span-4 h-full items-center flex">
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                Project Name
              </div>
              <select
                type="text"
                v-model="projectName"
                placeholder="Project Name"
                class="rounded h-12 bg-white w-full outline-none px-2 text-gray-600"
              >
                <option
                  :value="p.projectName"
                  v-for="(p, index) in projects"
                  :key="'pn' + index"
                >
                  {{ p.projectName }}
                </option>
              </select>
            </div> -->

            <div class="col-span-12 sm:col-span-2 h-full items-center flex">
              <div
                class="sm:block hidden flex-shrink whitespace-nowrap mr-3 text-left text-sm mb-1"
              >
                Status
              </div>
              <select
                v-model="active"
                placeholder="active"
                class="rounded h-12 bg-white w-full outline-none px-2 text-gray-600"
              >
                <option :value="true">Active</option>
                <option :value="false">Inactive</option>
              </select>
            </div>

            <div class="col-span-12 sm:col-span-4 h-12 items-center flex">
              <vue-google-autocomplete
                :options="autocompleteOptions"
                id="map"
                v-model="address"
                country="au"
                ref="autocomplete"
                classname="rounded h-12 bg-white w-full outline-none px-2 text-gray-600"
                placeholder="Project Address"
                @placechanged="getAddressData"
                v-on:inputChange="onInput"
              >
              </vue-google-autocomplete>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full bg-white">
        <div class="container mx-auto p-4">
          <div class="grid grid-cols-4 gap-4">
            <div
              class="col-span-4 sm:col-span-1"
              v-for="(j, index) in jobs"
              :key="index"
            >
              <div
                :class="[
                  editMode && editMode.id == j.id ? 'pulse-glow' : '',
                  j.status == 'COMPLETED'
                    ? 'bggreenstripes bg-green-600'
                    : ' bg-gray-100',
                ]"
                class="w-full rounded p-4 shadow relative"
              >
                <div
                  class="absolute top-0 right-0 -m-1"
                  v-if="index >= 1 || j.id"
                  @click="removeJob(index)"
                >
                  <i
                    class="fa-solid fa-circle-minus text-red-600 hover:text-red-700 cursor-pointer text-lg"
                  ></i>
                </div>
                <div class="w-full relative mb-1">
                  <div class="absolute top-0 right-0 mr-4 mt-5">
                    <i class="fa-solid fa-calendar-days"></i>
                  </div>

                  <datetime
                    v-model="j.date"
                    type="datetime"
                    placeholder="Date and time"
                    format="DD-MM-YYYY HH:mm"
                    input-class=" w-full rounded bg-gray-200 pr-6 outline-none pl-6 h-12 text-gray-500 text-sm"
                  ></datetime>
                </div>
                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Mix Code
                  </div>
                  <input
                    type="text"
                    v-model="j.mixCode"
                    placeholder="ie: 123"
                    class="rounded h-12 text-right w-full bg-gray-50 outline-none px-6 text-gray-600"
                  />
                </div>
                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Meters
                  </div>
                  <input
                    type="text"
                    v-model="j.meters"
                    placeholder="ie: 4"
                    class="rounded h-12 text-right w-full bg-gray-50 outline-none px-6 text-gray-600"
                  />
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Requirements
                  </div>
                  <input
                    type="text"
                    v-model="j.requirements"
                    placeholder="Leave comment..."
                    class="rounded h-12 text-right w-full bg-gray-50 text-xs outline-none px-6 text-gray-600"
                  />
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Notes
                  </div>
                  <input
                    type="text"
                    v-model="j.notes"
                    placeholder="Leave comment..."
                    class="rounded h-12 text-right w-full bg-gray-50 text-xs outline-none px-6 text-gray-600"
                  />
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-gray-500"
                  >
                    Tester
                  </div>
                  <select
                    type="text"
                    v-model="j.contractor"
                    placeholder="Leave comment..."
                    class="rounded h-12 text-right w-full bg-gray-50 text-xs outline-none px-6 text-gray-600"
                  >
                    <option
                      v-for="(c, cIndex) in contractors"
                      :key="'con' + cIndex"
                      :value="c"
                    >
                      {{ c.name }}
                    </option>
                  </select>
                </div>

                <div class="w-full relative mb-1">
                  <div
                    class="absolute top-0 left-0 ml-2 mt-1 text-xs text-white"
                  >
                    Status
                  </div>
                  <select
                    v-model="j.status"
                    :class="[
                      j.status == 'ON-CALL' ? 'bg-yellow-600 text-white' : '',
                      j.status == 'FIRM'
                        ? ' bggreenstripes bg-green-600 text-white'
                        : '',
                      j.status == 'COMPLETED' ? ' bg-green-600 text-white' : '',
                      j.status == 'CANCELLED' ? 'bg-red-700 text-white' : '',
                    ]"
                    class="rounded h-12 w-full text-right text-sm bg-gray-50 outline-none px-2"
                  >
                    <option>ON-CALL</option>
                    <option>FIRM</option>
                    <option>CANCELLED</option>
                    <option>COMPLETED</option>
                  </select>
                </div>

                <div class="w-full mt-2" @click="duplicateJob(j)">
                  <div
                    class="rounded bg-gray-500 text-white rounded justify-center flex items-center cursor-pointer hover:bg-gray-800 h-14"
                  >
                    Duplicate
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-span-4 sm:col-span-1 flex items-center"
              @click="addJob()"
            >
              <div
                class="w-full h-96 flex items-center rounded-lg hover:text-white justify-center bg-gray-100 border-2 border-dashed border-gray-400 cursor-pointer hover:border-gray-600 hover:bg-gray-500 rounded p-4 shadow"
              >
                <div class="w-full text-center">
                  <i class="fa-solid fa-plus text-4xl"></i>
                  <div>Add New Job</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateCustomer from "@/components/cards/createCustomer.vue";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { bus } from "../../main";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import {
  putFire,
  postFire,
  getContractors,
  getJobsByProjectId,
  getFire,
  deleteFire,
  getProjects,
} from "../../firestore/utils";
import { getTimeStamp, setGeoPoint } from "../../firebase";
const geofire = require("geofire-common");
import Multiselect from "vue-multiselect";

export default {
  props: ["editJob", "date", "prevDate"],
  data() {
    return {
      overrideAddress: "",
      loadingSelect: false,
      contractors: [],
      editMode: false,
      projects: [],
      autocompleteOptions: {
        types: ["geocode"], // Set the desired place types
      },
      active: true,
      customers: [],
      search: "",
      customer: null,
      createCustomer: false,
      client: ["KCT"],
      company: "",
      contractor: null,
      customerId: "",
      address: "",
      projectId: "",
      projectName: "",
      contact: "",
      jobs: [],
      schema: {
        status: "ON-CALL",
        phone: "",
        email: "",
        contractor: null,
        date: "",
        meters: "",
        requirements: "",
        notes: "",
        mixCode: "",
      },
    };
  },
  watch: {
    customer() {
      this.getProjectById();
    },
  },
  methods: {
    onInput(a) {
      // this.autoSave()
      // this.overrideAddress = a.newVal
      // this.address = this.overrideAddress
      this.overrideAddress = a.newVal
    },
    async getContractors() {
      this.contractors = await getContractors();
    },
    resetData() {
      this.editMode = null;
      // this.projectId = ""
      this.projectName = "";
      this.projects = [];
      this.address = null;
      // this.customerId = ""
      // this.customer = null
    },
    selectProject() {
      const p = this.projectId
      // this.editMode = p;
      this.getJobs(p.projectId);
      // this.projects = [];

      const preloadedAddress = {
        address: `${p.address.street_number} ${p.address.route} ${p.address.locality}, ${p.address.administrative_area_level_1} ${p.address.country} ${p.address.postal_code}`,
        city: p.address.locality,
        state: p.address.administrative_area_level_1,
        country: p.address.country,
        postalCode: p.address.postal_code,
      };

      this.address = preloadedAddress.address;
    },
    searchProjectId() {
      if (this.projectId) {
        this.projects = [];
        const url =
          "http://127.0.0.1:5001/ktc-app-33f33/us-central1/widgets/search";

        this.axios
          .get(url, {
            params: {
              indexName: "jobs",
              filter: `projectId:${this.projectId}`,
              search: this.search,
              twilio: null,
            },
          })
          .then((response) => {
            console.log("response form projects", response.data);
            this.projects = [];
            if (this.projects.length <= 0) this.resetData();
            this.projects = response.data;
          })
          .catch((e) => {
            console.log("Error algolia", e);
          });
      }
    },
    searchCustomers() {
      this.loadingSelect = true;
      // this.customers = [];
      const url =
        "https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/search";

      this.axios
        .get(url, {
          params: {
            indexName: "customers",
            filter: null,
            search: this.search,
            twilio: null,
          },
        })
        .then((response) => {
          this.customers = response.data;
          this.loadingSelect = false;
        })
        .catch((e) => {
          this.loadingSelect = false;
          console.log("Error algolia", e);
        });
    },
    async getProjectById() {
      console.log("this.customer", this.customer);
      const objects = await getProjects({ companyName: this.customer.company });

      const uniqueObjects = objects.filter((obj, index, self) => {
        return index === self.findIndex((otherObj) => otherObj.projectId === obj.projectId);
      });

      console.log("uniqueObjects", uniqueObjects)

      this.projects = uniqueObjects;
    },
    async getJobs(projectId) {
      let jobs = await getJobsByProjectId(projectId);

      jobs = jobs.sort((a, b) => a.date - b.date);

      jobs.forEach((job) => {
        job.date = new Date(job.date.seconds * 1000).toISOString()
      });

      this.jobs = jobs.sort((a, b) => a.date - b.date);
      this.active = this.jobs[0].active

    },
    removeJob(index) {
      if (this.jobs[index].id) this.deleteJob(this.jobs[index].id);

      let jobs = JSON.stringify(this.jobs);
      jobs = JSON.parse(jobs);
      jobs.splice(index, 1);
      this.jobs = jobs;

      if(this.jobs.length == 0) this.jobs.push(this.schema)
    },
    addJob() {
      const s = JSON.stringify(this.schema);
      this.jobs.push(JSON.parse(s));
    },
    duplicateJob(job) {
      const s = JSON.stringify(job);
      let _job = JSON.parse(s);
      if (_job.id) _job.id = "";
      this.jobs.push(_job);
    },
    getAddressData(addressData, placeResultData, id) {
      this.address = addressData;
    },
    async submitForm() {
      this.loading = true;
      // GEO Hash
      const lat = this.address.latitude;
      const lng = this.address.longitude;
      const geohash = geofire.geohashForLocation([lat, lng]);
      // GEO point
      const geopoint = setGeoPoint({
        latitude: this.address.latitude,
        longitude: this.address.longitude,
      });

      let newArray = [];
      // Loop Jobs and save
      for (let i = 0; i < this.jobs.length; i++) {
        const payload = {
          // Client
          client: this.client[0],
          active: this.active,
          // Customer
          company: this.customer.company,
          phone: this.customer.phone,
          email: this.customer.email,
          contact: this.customer.contact,
          customerId: this.customer.id,
          // Project Specific
          address: this.address,
          projectId: this.projectId,
          projectName: this.projectName,
          geohash,
          geopoint,
          // Job specific
          status: this.jobs[i].status,
          contractor: this.jobs[i].contractor,
          date: getTimeStamp(new Date(this.jobs[i].date)),
          meters: this.jobs[i].meters,
          mixCode: this.jobs[i].mixCode,
          published: false,
          requirements: this.jobs[i].requirements,
          notes: this.jobs[i].notes,
        };
        console.log("payload", payload);
        const a = await postFire({
          collection: "jobs",
          body: payload,
        });
        a.date = new Date(a.date.seconds * 1000).toISOString();
        newArray.push(a);
      }

      console.log("newArray", newArray);
      this.jobs = newArray;
      await this.addJobsToClient(this.customer.id);
      this.customer = null;
      this.jobs = [this.schema];
      this.projectName = "";
      this.projectId = "";
      this.status = "";
      this.search = "";
      this.projects = [];
      this.address = "";
      this.$refs.autocomplete.clear();

      bus.$emit("updateJob", null);
    },
    async editForm() {
      this.loading = true;
      let newArray = [];
      // Loop Jobs and save
      for (let i = 0; i < this.jobs.length; i++) {
        const payload = {
          // Client
          client: this.client[0],
          active: this.active,
          status: this.jobs[i].status,
          contractor: this.jobs[i].contractor,
          date: getTimeStamp(new Date(this.jobs[i].date)),
          meters: this.jobs[i].meters,
          mixCode: this.jobs[i].mixCode,
          published: false,
          requirements: this.jobs[i].requirements,
          notes: this.jobs[i].notes,
        };

        let a;

        if (this.jobs[i].id) {
          a = await putFire({
            collection: "jobs",
            doc: this.jobs[i].id,
            body: payload,
          });
        } else {
          a = await postFire({
            collection: "jobs",
            body: payload,
          });
        }

        a.date = new Date(a.date.seconds * 1000).toISOString();
        newArray.push(a);
      }
      // alert(this.date)

      setTimeout(function () {
        bus.$emit("changeDate", { prevDate: this.prevDate, date: this.date });
      }, 1000);

      // this.jobs = newArray
      await this.addJobsToClient(this.jobs[0].customerId);
      // this.$router.back();
      bus.$emit("showAddProjectFunc", false);
    },
    async deleteJob(jobId) {
      await deleteFire({ collection: "jobs", doc: jobId });
    },

    addJobsToClient(customerId) {
      return new Promise(async (res, reject) => {
        const newArray = this.jobs.map((obj) => {
          return {
            id: obj.id,
            project: this.projectId,
            address: this.address,
            projectName: this.projectName,
            active: true,
          };
        });

        await putFire({
          collection: "customers",
          doc: customerId,
          body: { jobs: newArray },
        });

        res();
      });
    },
  },
  components: {
    datetime: Datetime,
    VueGoogleAutocomplete,
    CreateCustomer,
    Multiselect,
  },
  created() {
    this.getContractors();
    if (this.editJob) {
      this.editMode = this.editJob;
      this.selectProject(this.editMode);
    }

    this.jobs.push(this.schema);

    bus.$on("closeCreateCustomer", (data) => {
      this.createCustomer = data.status;
      if (data.customer) {
        this.customer = data.customer;
      }
    });
  },
};
</script>

<style lang="postcss">
@keyframes pulse-glow {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 128, 128, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(0, 128, 128, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 128, 128, 0);
  }
}

.pulse-glow {
  animation: pulse-glow 2s infinite;
}

.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #01bc38 !important;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: #01bc38 !important;
}
</style>
