<template>
    <div
      v-if="currentUser.role == 'admin'"
      class="container mx-auto h-full items-center hidden sm:flex"
    >
      <div class="flex-shrink">
        <img
          src="https://images.squarespace-cdn.com/content/v1/5fc6d3c16879d031da0e1579/1606868635040-DILXV7ATMCTE3V243QTO/kct-logo-main.png?format=1500w"
          width="120"
          alt="KTC"
        />
      </div>
      <div class="flex-shrink ml-auto flex h-full items-center flex py-4">
        <router-link
          :to="{ name: 'Dashboard' }"
          class="flex-shrink mr-10 h-14 flex items-center hover:border-primary cursor-pointer border-b-2 border-transparent"
          ><i class="fa-solid fa-gauge-high text-primary mr-1"></i> Dashboard</router-link
        >
        <router-link
          :to="{ name: 'Home' }"
          class="flex-shrink mr-10 h-14 flex items-center hover:border-primary cursor-pointer border-b-2 border-transparent"
          ><i class="fa-regular fa-clock text-primary mr-1"></i> Job Schedule</router-link
        >
        <router-link
          :to="{ name: 'ClientBook' }"
          class="flex-shrink mr-10 h-14 flex items-center hover:border-primary cursor-pointer border-b-2 border-transparent"
          ><i class="fa-solid fa-user-group text-primary mr-1"></i> Client Book</router-link
        >
        <router-link
          :to="{ name: 'CallLog' }"
          class="flex-shrink mr-10 h-14 flex items-center hover:border-primary cursor-pointer border-b-2 border-transparent"
          ><i class="fa-solid fa-headset text-primary mr-1"></i> Call Log</router-link
        >

        <router-link
          :to="{ name: 'Users' }"
          class="flex-shrink mr-2 h-14 flex items-center hover:border-primary cursor-pointer border-b-2 border-transparent"
          ><i class="fa-solid fa-user-shield text-primary mr-1"></i> Users</router-link
        >

        <span @click="logout()"
          class="px-4 bg-gray-100 hover:bg-gray-500 hover:text-white py-2 cursor-pointer rounded-lg flex-shrink ml-2 h-14 flex items-center hover:border-primary cursor-pointer border-b-2 border-transparent"
          >Logout </span>

      </div>
    </div>
</template>

<script>
import {signMeOut} from "../../firestore/utils"
export default {
    computed:{
        currentUser(){
            return this.$store.state.currentUser
        }
    },
    methods:{
      async logout(){
        await signMeOut()
        this.$router.push({name: 'Login'})
      }
    }
}
</script>