<template>
  <div
    class="relative h-full lg:mt-0 -mt-4 bg-white rounded-t-xl flex flex-wrap"
  >
    <nav-menu />

    <div class="w-full bg-gray-900">
      <div class="container mx-auto h-40 items-center flex">
        <div class="flex-shrink text-left">
          <h1 class="text-white header-heavy text-3xl tracking-tight">
            Call Log
          </h1>
        </div>

        <div class="flex-shrink ml-auto flex flex-wrap ml-auto">
          <div class="flex-grow" v-if="date">
            <div class="flex w-full items-center space-x-4">
              <div
                class="flex-shrink flex items-center text-xs text-gray-200"
                v-if="prevDate"
              >
                From
                <datetime
                  v-model="prevDate"
                  type="date"
                  input-class="rounded text-center h-12 ml-2 bg-gray-800 outline-none px-2 w-40 text-base text-gray-300"
                  class="theme-orange"
                ></datetime>
              </div>
              <div
                class="flex-shrink flex items-center text-xs text-gray-200"
                v-if="date"
              >
                To
                <datetime
                  v-model="date"
                  type="date"
                  input-class="rounded text-center h-12 ml-2 bg-gray-800 outline-none px-2 w-40 text-base text-gray-300"
                  class="theme-orange"
                ></datetime>
              </div>
              <div class="flex-shrink text-white" @click="previousDate()">
                <div
                  class="rounded bg-secondary cursor-pointer hover:bg-primary text-white h-12 px-4 items-center justify-center flex"
                >
                  <i class="fa-solid fa-chevron-left cursor-pointer mr-2"></i>
                  Previous
                </div>
              </div>
              <div class="flex-shrink text-white" @click="nextDate()">
                <div
                  class="rounded bg-secondary cursor-pointer hover:bg-primary text-white h-12 px-4 items-center justify-center flex"
                >
                  Next
                  <i class="ml-2 fa-solid fa-chevron-right cursor-pointer"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-left container mx-auto">
      <h1
        v-if="reminderCalls.length > 0"
        class="mt-10 mb-4 text-secondary header-heavy text-xl tracking-tight"
      >
        Set Reminders
      </h1>

      <div
        class="w-full"
        v-for="(p, index) in reminderCalls"
        :key="'preminderCalls' + index"
      >
        <reminder-tile :project="p" :jobId="reminderCalls[0].id" />
      </div>

      <div
        :class="
          reminderCalls.length > 0
            ? 'border-t border-gray-200 border-dashed'
            : ''
        "
        class="w-full mt-4 pt-4 pb-20"
        v-if="date == prevDate"
      >
        <h1 class="mb-4 text-secondary header-heavy text-xl tracking-tight">
          Daily Calls
        </h1>

        <div class="w-full" v-for="(p, index) in projects" :key="'p' + index">
          <project-tile :showCall="true" :project="p" :date="date" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectTile from "@/components/cards/ProjectTile";
import ReminderTile from "@/components/cards/ReminderTile";
import NavMenu from "@/components/headers/navMenu";
import {
  GetReminderCalls,
  GetRemindersByDay,
  GetRemindersByFriday,
  getJobsByProjectId,
} from "../firestore/utils";
import { bus } from "../main";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

export default {
  data() {
    return {
      isFriday: false,
      projects: [],
      reminderCalls: [],
      date: "",
      prevDate: "",
    };
  },
  watch: {
    date() {
      this.initiate();
      this.checkForFriday();
    },
    prevDate() {
      this.initiate();
      this.checkForFriday();
    },
  },
  computed: {
    isToday() {
      if ((this.date = this.$moment().toISOString())) {
        return true;
      } else {
        return false;
      }
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    nextDate() {
      this.date = this.$moment(this.date).add(1, "days").toISOString();
      this.prevDate = this.$moment(this.prevDate).add(1, "days").toISOString();
    },
    previousDate() {
      this.date = this.$moment(this.date).subtract(1, "days").toISOString();
      this.prevDate = this.$moment(this.prevDate)
        .subtract(1, "days")
        .toISOString();
    },
    async initiate() {
      this.reminderCalls = [];
      this.projects = [];
      // Get manual reminders
      const reminderCalls = await GetRemindersByDay({
        start: new Date(this.$moment(this.prevDate)
          .startOf("day")),
        end: new Date(this.$moment(this.date)
          .endOf("day")),
      });

      console.log("reminderCalls", reminderCalls);

      for (let i = 0; i < reminderCalls.length; i++) {
        const _job = await getJobsByProjectId(reminderCalls[i].projectId);

        const existingProject = this.reminderCalls.find(
          (call) => call.projectId === _job[0].projectId
        );

        if (!existingProject) {
          this.reminderCalls.push({
            reminder: reminderCalls[i],
            id: _job[0].id,
            projectId: _job[0].projectId,
            client: _job[0].client,
            projectName: _job[0].projectName,
            projectAddress: _job[0].formattedAddress,
            contact: _job[0].contact,
            phone: _job[0].phone,
            email: _job[0].email,
            active: _job[0].active,
            lastCalled: _job[0].lastCalled,
            jobs: [_job],
          });
        }
      }
      // Daily calls
      // Now jobs to projects - daily calls
      const jobs = await GetReminderCalls();

      jobs.map((i) => {
        const exists = this.projects.filter((x) => x.projectId == i.projectId);
        if (exists.length > 0)
          this.projects[this.projects.indexOf(exists[0])].jobs.push(i);
        else {
          this.projects.push({
            id: i.id,
            projectId: i.projectId,
            projectName: i.projectName,
            projectAddress: i.formattedAddress,
            contact: i.contact,
            client: i.client,
            phone: i.phone,
            email: i.email,
            active: i.active,
            lastCalled: i.lastCalled,
            jobs: [i],
          });
        }
      });

      if (this.isFriday) {
        const fridayjobs = await GetRemindersByFriday();

        fridayjobs.map((i) => {
          const exists = this.projects.filter(
            (x) => x.projectId == i.projectId
          );
          if (exists.length > 0)
            this.projects[this.projects.indexOf(exists[0])].jobs.push(i);
          else {
            this.projects.push({
              id: i.id,
              projectId: i.projectId,
              projectName: i.projectName,
              projectAddress: i.formattedAddress,
              contact: i.contact,
              client: i.client,
              phone: i.phone,
              email: i.email,
              active: i.active,
              lastCalled: i.lastCalled,
              jobs: [i],
            });
          }
        });
      }
    },
    checkForFriday() {
      let startDate = this.$moment(this.prevDate);
      let endDate = this.$moment(this.date);

      let hasFriday = false;

      // Loop through the days
      if (startDate.isSame(endDate, "day")) {
        if (startDate.day() === 5) {
          hasFriday = true;
        }
      } else {
        while (startDate.isBefore(endDate)) {
          if (startDate.day() === 5) {
            // 5 represents Friday in moment's week days
            hasFriday = true;
            break;
          }
          startDate.add(1, "days");
        }
      }

      if (hasFriday) {
        console.log("A Friday exists between the two dates");
        this.isFriday = true;
      } else {
        console.log("No Friday exists between the two dates");
        this.isFriday = false;
      }
    },
  },
  created() {
    this.initiate();
    this.date = this.$moment().toISOString();
    this.prevDate = this.$moment().toISOString();
    this.checkForFriday();

    bus.$on("reloadInitiate", (data) => {
      this.projects = [];
      this.initiate();
    });
  },
  components: {
    ProjectTile,
    NavMenu,
    ReminderTile,
    Datetime,
  },
};
</script>
