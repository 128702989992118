<template>
  <div
    class="relative h-full lg:mt-0 -mt-4 bg-white rounded-t-xl flex flex-wrap"
  >
    <nav-menu />

    <div class="w-full bg-gray-900">
      <div class="container mx-auto h-40 items-center flex flex-wrap">
        <div class="flex-shrink text-left xs:order-2 sm:order-1">
          <h1 class="text-white header-heavy text-3xl tracking-tight">
            Dashboard
          </h1>
        </div>

        <div class="flex-shrink ml-auto flex flex-wrap ml-auto xs:order-1 sm:order-2">
          <div class="flex-grow" v-if="date">
            <div class="flex w-full items-center space-x-4">
              <div
                class="flex-shrink flex items-center text-xs text-gray-200"
                v-if="prevDate"
              >
                From
                <datetime
                  v-model="prevDate"
                  type="date"
                  input-class="rounded text-center h-12 ml-2 bg-gray-800 outline-none px-2 w-40 text-base text-gray-300"
                  class="theme-orange"
                ></datetime>
              </div>
              <div
                class="flex-shrink flex items-center text-xs text-gray-200"
                v-if="date"
              >
                To
                <datetime
                  v-model="date"
                  type="date"
                  input-class="rounded text-center h-12 ml-2 bg-gray-800 outline-none px-2 w-40 text-base text-gray-300"
                  class="theme-orange"
                ></datetime>
              </div>
              <div class="flex-shrink text-white" @click="previousDate()">
                <div
                  class="rounded bg-secondary cursor-pointer hover:bg-primary text-white h-12 px-4 items-center justify-center flex"
                >
                  <i class="fa-solid fa-chevron-left cursor-pointer mr-2"></i>
                  Previous
                </div>
              </div>
              <div class="flex-shrink text-white" @click="nextDate()">
                <div
                  class="rounded bg-secondary cursor-pointer hover:bg-primary text-white h-12 px-4 items-center justify-center flex"
                >
                  Next
                  <i class="ml-2 fa-solid fa-chevron-right cursor-pointer"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="h-full bg-gray-100 w-full">
    <div class="sm:container sm:mx-auto grid col-span-12 sm:gap-10 sm:p-10 " >
      <!-- <div class="col-span-12">
        <JobsChart />
      </div> -->
      <div class="col-span-12 sm:col-span-6">
        <DateRangeJobsCard  v-if="currentData" :dateStart="$moment(prevDate).format('DD MMM YYYY')" :dateEnd="$moment(date).format('DD MMM YYYY')" :metrics="currentData" />
      </div>
      <div class="col-span-12 sm:col-span-6">
        <NextDayJobsCard v-if="currentData" :fromDate="$moment(date).add(1, 'days').format('DD MMM YYYY')" :toDate="$moment(date).add(1, 'days').format('DD MMM YYYY')" :daysBetweenDates="daysBetweenDates" :metrics="tomorrowData" :daybeforeMetrics="todayData" />
      </div>
      <div class="col-span-12">
        <TesterCard :dateFrom="prevDate" :dateTo="date"  />
      </div>
    </div>
  </div>
  </div>
</template>
  
  <script>
import NavMenu from "@/components/headers/navMenu";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { getTimeStamp, db } from "../firebase";

import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import DateRangeJobsCard from "@/components/dashboard/DateRangeJobsCard.vue";
import NextDayJobsCard from "@/components/dashboard/NextDayJobsCard.vue";
import TesterCard from "@/components/dashboard/TesterCard.vue";
import JobsChart from "@/components/dashboard/jobsChart.vue";

let unsubscribe
export default {
  data() {
    return {
      date: "",
      prevDate: "",
      daysBetweenDates:  0,
      schema: {
        projects: [],
        totalJobs: 0,
        completedJobs: 0,
        onCallJobs: 0,
        cancelledJobs: 0
      },
      currentData: null,
      daybeforeData: null,
      tomorrowData: null,
      todayData: null,
    };
  },
  watch: {
    date() {
      unsubscribe
      this.currentData = this.schema
      this.daybeforeData = this.schema
      this.tomorrowData = this.schema
      this.todayData = this.schema
      this.initiate();
    },
    prevDate() {
      unsubscribe
      this.currentData = this.schema
      this.daybeforeData = this.schema
      this.tomorrowData = this.schema
      this.todayData = this.schema
      this.initiate();
    },
  },
  computed:{
    isToday(){
      if(this.date = this.$moment().add(11, "hours").toISOString()){
        return true
      } else {
        return false
      }
    },
    currentUser(){
      return this.$store.state.currentUser
    }
  },
  methods: {
    
    nextDate() {
      this.date = this.$moment(this.date).add(1, "days").toISOString();
      this.prevDate = this.$moment(this.prevDate).add(1, "days").toISOString();
    },
    previousDate() {
      this.date = this.$moment(this.date).subtract(1, "days").toISOString();
      this.prevDate = this.$moment(this.prevDate)
        .subtract(1, "days")
        .toISOString();
    },
    initiate(){
      this.daysBetweenDates =  this.$moment(this.date).diff(this.prevDate, 'days');
      console.log("days between", this.daysBetweenDates)
      this.getItems({dateFrom: this.prevDate, dateTo: this.date}, 'currentData')


      // Get equal days before
      const tomorrowDays = {
        dateFrom: this.$moment(this.date).add(1, "days").add(11, "hours").toISOString(), 
        dateTo: this.$moment(this.date).add( 1, "days").add(11, "hours").toISOString()
      }
       console.log("equalDays", tomorrowDays)
      this.getItems(tomorrowDays, 'tomorrowData')

      // this.getItems({dateFrom: this.prevDate, dateTo: this.date}, 'todayData')
    },
    async getItems(dates, target) {
      this.rowData = [];
      if (this.prevDate && this.date) {
        const start = this.$moment(dates.dateFrom).startOf("day");
        const end = this.$moment(dates.dateTo).endOf("day");

        let payload = {
          collection: "jobs",
          dateFrom: getTimeStamp(new Date(start)),
          dateTo: getTimeStamp(new Date(end)),
        };

        let q = query(
            collection(db, "jobs"),
            where("date", ">", payload.dateFrom),
            where("date", "<", payload.dateTo),
            orderBy("date")
          );
        

          unsubscribe = onSnapshot(q, (snapshot) => {
          let _jobs = [];
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              _jobs.push({ ...change.doc.data(), id: change.doc.id });
              this.formatJob(_jobs, target);
            }
          });
        });
      }
    },
    formatJob(jobs, target){
      console.log("jobs", jobs)
      // find Projects
      let projects = []

      let totalJobs = 0
      let completedJobs = 0
      let onCallJobs = 0
      let cancelledJobs = 0

      jobs.forEach(i => {
        const projectExists = projects.filter(p => p.projectId == i.projectId)
        if(projectExists.length > 0){
          // Get the index
          const projectIndex = projects.indexOf(projectExists[0])
          projects[projectIndex].jobCount += 1
        } else projects.push({projectId: i.projectId, jobCount: 1}) // Otherwise push the first object
        
        // Get metrics
        totalJobs += 1
        if(i.status == 'COMPLETED') completedJobs += 1
        if(i.status == 'ON-CALL') onCallJobs += 1
        if(i.status == 'CANCELLED') cancelledJobs += 1
      })

      this[target] = {
        projects: projects,
        totalJobs: totalJobs,
        completedJobs: completedJobs,
        onCallJobs: onCallJobs,
        cancelledJobs: cancelledJobs
      }
    }
  },
  created() {
    
    this.initiate()
    this.date = this.$moment().add(11, "hours").toISOString();
    this.prevDate = this.$moment().add(11, "hours").toISOString();
  },
  components: {
    NavMenu,
    Datetime,
    DateRangeJobsCard,
    NextDayJobsCard,
    TesterCard,
    JobsChart
  },
};
</script>
  