<template>
  <div class="w-full">
    <h2 class="my-10 text-secondary header-regular text-3xl">
      Save your details
    </h2>

    <div class="relative z-0 w-full mb-5">
      <input
        v-model="name"
        type="text"
        name="name"
        placeholder=" "
        class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
      />
      <label
        for="name"
        class="absolute duration-300 top-3 left-0 -z-1 origin-0 text-gray-500"
        >Enter name</label
      >
    </div>
    <div class="relative z-0 w-full mb-5">
      <input
        v-model="phone"
        type="text"
        name="phone"
        placeholder=" "
        class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
      />
      <label
        for="phone"
        class="absolute left-0 duration-300 top-3 -z-1 origin-0 text-gray-500"
        >Enter phone</label
      >
    </div>
    <div class="text-left relative z-0 w-full mb-5 pt-4 mt-4">
      <h2 class="text-gray-500">Set your address</h2>
      <p>This is used to schedual jobs in your area</p>
      <vue-google-autocomplete
        id="map"
        country="au"
        classname="rounded mt-3 bg-gray-100 w-full rounded h-14 outline-none px-2 text-gray-600"
        placeholder="Address"
        v-on:placechanged="getAddressData"
      >
      </vue-google-autocomplete>
    </div>

    <iframe
      v-if="address"
      width="100%"
      height="250"
      style="border: 0"
      loading="lazy"
      allowfullscreen
      referrerpolicy="no-referrer-when-downgrade"
      :src="
        'https://www.google.com/maps/embed/v1/place?key=AIzaSyAgzzzBwnKAnilSbETwkny6ApTz18jlHr4&q=' +
        address.latitude +
        ',' +
        address.longitude
      "
    >
    </iframe>

    <div class="w-full mt-10">
      <button
        @click="saveUser()"
        class="h-16 w-full bg-primary text-white rounded mb-4"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {setGeoPoint} from "../../firebase"
import { putFire } from "../../firestore/utils";
import VueGoogleAutocomplete from "vue-google-autocomplete";
const geofire = require('geofire-common');

export default {
  data() {
    return {
      name: "",
      phone: "",
      address: "",
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    getAddressData(addressData, placeResultData, id) {
      this.address = addressData;
    },
    async saveUser() {
      // GEO Hash
      const lat = this.address.latitude;
      const lng = this.address.longitude;
      const geohash = geofire.geohashForLocation([lat, lng]);
      console.log("geohash", geohash)
      // GEO point
      const geopoint = setGeoPoint({latitude: this.address.latitude, longitude: this.address.longitude});
      // Save 
      const payload = {
        name: this.name,
        phone: this.phone,
        address: this.address,
        geopoint,
        geohash
      };

      await putFire({
        collection: "users",
        doc: this.currentUser.id,
        body: payload,
      });
      // Update currentUser in vuex with name.... etc
      this.$store.commit("updateState", {
        state: "currentUser",
        body: { ...this.currentUser, ...payload },
      });
      // redirect
      this.$router.push({ name: "Home" });      
    },

  },
  created() {
    console.log("this.$store.state.currentUser", this.$store.state.currentUser);
  },
  components: {
    VueGoogleAutocomplete,
  },
};
</script>