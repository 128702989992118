<template>
  <div class="w-full h-screen">
    <transition name="slide">
      <div v-if="slide == 1"
        class="absolute p-6 flex items-center w-full h-full top-0 left-0 z-10"
      >
        <upload-image  />
      </div>
    </transition>
    <transition name="slide">
      <div v-if="slide == 2"
        class="absolute p-6 flex items-center w-full h-full top-0 left-0 z-20"
      >
        <details-onboarding />
      </div>
    </transition>
  </div>
</template>

<script>
import UploadImage from "@/components/onboarding/upload-image";
import DetailsOnboarding from "@/components/onboarding/details";
import { bus } from "../main";

export default {
  data() {
    return {
      slide: 1,
    };
  },
  components: {
    UploadImage,
    DetailsOnboarding,
  },
  created() {
    bus.$on("changeSlide", (payload) => {
      this.slide = payload.slide;
    });
  },
};
</script>