<template>
  <div
    class="fixed z-40 top-0 left-0 overflow-y-scroll h-screen bg-white w-full"
  >
    <div class="w-full bg-gray-900 h-20 flex items-center px-4">
      <div class="flex-shrink">
        <div
          @click="closeModal()"
          class="bg-white bg-opacity-5 flex items-center justify-center h-12 w-12 rounded mr-4"
        >
          <svg
            class="w-8 h-8 text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
      </div>

      <div class="flex-grow text-left relative">
        <h2 class="text-white header-bold">Select a tester</h2>
        <!-- <div class="absolute top-0 z-20 text-white right-0 mt-3 mr-2">
          <svg
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z"></path>
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          placeholder="Search for contractor"
          class="w-full h-12 bg-gray-700 text-white outline-none px-2 rounded border-gray-500 border"
        /> -->
      </div>
      
    </div>
    <div
      v-for="(c, index) in contractors"
      :key="index"
      class="w-full flex border-b border-gray-200"
    >
      <div class="flex-shrink">
        <img v-if="c.avatar"
          :src="c.avatar"
          class="h-20 w-20 object-cover"
          alt="Contractor Avatar"
        />
        <img v-else
          src="https://avatarairlines.com/wp-content/uploads/2020/05/Male-placeholder.jpeg"
          class="h-20 w-20 object-cover"
          alt="Contractor Avatar"
        />
      </div>
      <div @click="showContractorModal(c)" class="flex-grow text-left pt-4 px-4">
        <span class="header-heavy tracking-tight text-base">
          {{ c.name }}
        </span>
        <div class="w-full text-sm text-gray-600">Services area: {{c.address.locality}}</div>
      </div>
      <div
        @click="showContractorModal(c)"
        class="flex-shrink items-center h-20 px-4 flex"
      >
        <svg
          class="w-6 h-6 text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
    </div>
    
  </div>
</template>

<script>
import { bus } from "../../main";
import {getContractors} from "../../firestore/utils"
export default {
  props:["assigningToJob"],
  data() {
    return {
      contractors:[]
    };
  },
  methods: {
    async getItems(){
      this.contractors = await getContractors()
    },
    closeModal() {
      bus.$emit("showContractorCardFunc", false);
    },
    showContractorModal(contractor) {
      bus.$emit("loadOwnerCardFunc", contractor);
    },
  },
  created(){
    this.getItems()
  }
};
</script>
