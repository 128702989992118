<template>
  <div
    class="relative h-full lg:mt-0 -mt-4 bg-gray-200 rounded-t-xl flex flex-wrap"
  >
    <div class="w-56 hidden sm:block h-screen bg-secondary text-left p-4">
      <h2 class="text-white my-6">Filter Jobs</h2>
      <div class="mb-4">
        <label class="text-sm text-gray-400">Jobs Between Time</label>
        <input
          type="text"
          placeholder="Jobs starting after"
          class="bg-gray-900 w-full text-white rounded px-4 h-12"
        />
        <input
          type="text"
          placeholder="Job starting before"
          class="bg-gray-900 mt-2 w-full text-white rounded px-4 h-12"
        />
      </div>
      <div class="mb-4">
        <label class="text-sm text-gray-400">Jobs In Range</label>
        <input
          type="text"
          placeholder="100KM"
          class="bg-gray-900 w-full text-white rounded px-4 h-12"
        />
        <input
          type="text"
          placeholder="Kingscliff"
          class="bg-gray-900 mt-2 w-full text-white rounded px-4 h-12"
        />
      </div>

      <div class="mb-4">
        <label class="text-sm text-gray-400">Job Status</label>
        <select
          name=""
          id=""
          class="bg-gray-900 w-full text-white rounded px-4 h-12"
        >
          <option>ON-CALL</option>
          <option>FIRM</option>
          <option>CANCELLED</option>
        </select>
      </div>

      <div class="mb-4">
        <label class="text-sm text-gray-400">Contractor</label>
        <select
          name=""
          id=""
          class="bg-gray-900 w-full text-white rounded px-4 h-12"
        >
          <option>Contractor</option>
        </select>
      </div>
    </div>
    <div class="flex-grow" v-if="!loading">
      <div class="w-full flex" v-for="(j, index) in rowData" :key="index">
        <div class="flex-shrink">
          <div class="w-16 h-full bg-white bg-opacity-50 rounded-tl-lg">
            <div
              class="h-full items-center flex w-16 justify-center items-center text-xs header-heavy border-b border-gray-300"
            >
              {{ j.time }}
            </div>
          </div>
        </div>
        <div
          class="flex-grow p-4 sm:flex justify-start border-b border-gray-300"
        >
          <div
            class="w-full sm:w-auto sm:mr-2"
            v-for="(job, jIndex) in j.jobs"
            :key="'J' + jIndex"
          >
            <div
              @click="showWorkCard(job)"
              :class="
                job.status == 'COMPLETED'
                  ? 'bg-green-600 bg-oapcity-10 text-white'
                  : 'bg-white text-secondary'
              "
              class="flex sm:w-96 mb-2 relative w-full items-center rounded p-2 text-left"
            >
              <div v-if="job.status" class="absolute top-0 right-0 mr-1 -mt-2">
                <span
                  :class="jobStyles(job)"
                  class="px-3 py-1 rounded-full bg-gray-100 header-bold"
                  style="font-size: 9px"
                  >{{ job.status }}</span
                >
              </div>
             
              <!-- <div v-if="!job.tester" class="flex-shrink">
                <i class="fa-solid fa-ban"></i>
              </div> -->
              <div class="flex-grow">
                <div class="w-full">
                  <h2 class=" text-xs truncate"  v-if="job.client">
                    <span class="font-bold">Client: </span>{{ job.client }}
                  </h2>
                  <h2 class=" text-xs truncate"  v-if="job.customer">
                    <span class="font-bold">Customer: </span> {{ job.customer }}
                  </h2>
                  <h2 class=" text-xs truncate"  v-if="job.projectName">
                    <span class="font-bold">Project: </span> {{ job.projectName }}
                  </h2>
                  <h2 class="text-xs truncate"  v-if="job.meters">
                  <span class="font-bold">Metres: </span> {{ job.meters }}
                </h2>
                  <h2 class="text-xs truncate" v-if="job.requirements">
                  <span class="font-bold">Requirements: </span> {{ job.requirements }}
                </h2>
                  <h2 class="header-heavy text-xs truncate">
                    
                  </h2>
                  <h2 class="header-bold text-sm truncate">
                    {{ job.company }}
                  </h2>
                </div>
               <div class="w-2/3 my-2">
                <h2 class=" text-xs">
                  {{ job.fullAddress }}
                </h2>
               </div>
                

            
                  <div class="flex-shrink mt-2 mb-2">
                    <div
                v-if="job.tester"
                class="mt-1"
              ><span 
              :class="job.status == 'COMPLETED' ? 'bg-green-700' : 'bg-gray-100'"
              class="text-left bg-gray-100 px-2 py-1 rounded-full pr -2 text-xs font-normal">
                <span class="font-bold">Site Contact:</span> 
                {{ job.contact.split(" ")[0] }} {{ job.contact.split(" ")[1] }} | <a :href="'tel:'+job.phone" class="text-primary">{{ job.phone }}</a>
              </span>
              </div>
            

                    <div class="mt-1"
                v-if="job.tester"
              ><span 
              :class="job.status == 'COMPLETED' ? 'bg-green-700' : 'bg-gray-100'"
              class="text-left bg-gray-100 px-2 py-1 rounded-full pr -2 text-xs font-normal">
                <span class="font-bold">Tester:</span> 
                {{ job.tester.split(" ")[0] }} {{ job.tester.split(" ")[1] }}
              </span>
              </div>
            </div>
                 
         
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { postFire, setFire } from "../../firestore/utils";
import { getTimeStamp, db } from "../../firebase";
import { bus } from "../../main";

var sortJsonArray = require("sort-json-array");
import { Parser } from "json2csv";

export default {
  props: ["date", "search", "triggerExport"],
  data() {
    return {
      loading: false,
      rowData: [],
    };
  },
  watch: {
    triggerExport() {
      if (this.triggerExport) this.exportToCSV();
    },
    search() {
      this.filterResults();
    },
    date() {
      this.getItems();
    },
    rowData: {
      handler() {
        bus.$emit("jobs", this.rowData);
      },
      deep: true,
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    showWorkCard(i) {
      bus.$emit("showWorkCardFunc", i);
    },
    jobStyles(job) {
      if (job.status == "ON-CALL")
        return "bgyellowstripes text-white bg-yellow-600";
      if (job.status == "FIRM") return "bggreenstripes text-white bg-green-600";
      if (job.status == "CANCELLED") return "bg-red-800 bgredstripes text-white";
      if (job.status == "COMPLETED") return "bg-white text-green-600";
      // if (!job.contractor) return "bg-gray-100 bgredstripes text-white bg-red-900";
    },
    async activateListner() {
      this.loading = true;
      this.rowData = [];
      
      if( this.date){
        const prevDate = this.date.split("&")[0]
        const date = this.date.split("&")[1]
      const start = this.$moment(prevDate).startOf("day");
      const end = this.$moment(date).endOf("day");
      
      let payload = {
          collection: "jobs",
          dateFrom: getTimeStamp(new Date(start)),
          dateTo: getTimeStamp(new Date(end)),
          contractor: null,
        };

        if (this.currentUser.role != "admin")
          payload.contractor = this.currentUser.id;

      console.log("payload Date", payload)

      let q;
      if (payload.contractor) {
        // Show result only where contactor is equal to payload.tester
        // also testers can only see published jobs
        q = query(
          collection(db, "jobs"),
          where("date", ">", payload.dateFrom),
          where("date", "<", payload.dateTo),
          where("contractor.id", "==", payload.contractor),
          where("published", "==", true),
          orderBy("date")
        );
      } else {
        q = query(
          collection(db, "jobs"),
          where("date", ">", payload.dateFrom),
          where("date", "<", payload.dateTo),
          orderBy("date")
        );
      }

      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
    if (change.type === "added") {
      this.getItems()
    }
    if (change.type === "modified") {
      console.log("getting modified")
      this.getItems()
    }
    if (change.type === "removed") {
      this.getItems()
    }
  });
        
      });

    }
      this.loading = false;
      // console.log(":_jobs", _jobs)
    },

    async getItems() {
      console.log("getting new items")
      this.loading = true;
      this.rowData = [];
      if(this.date){
        const prevDate = this.date.split("&")[0]
        const date = this.date.split("&")[1]
      const start = this.$moment(prevDate).startOf("day");
      const end = this.$moment(date).endOf("day");
      
      let payload = {
          collection: "jobs",
          dateFrom: getTimeStamp(new Date(start)),
          dateTo: getTimeStamp(new Date(end)),
          contractor: null,
        };

        

        if (this.currentUser.role != "admin")
          payload.contractor = this.currentUser.id;


      let q;

      console.log("payload", payload)
      
      if (payload.contractor) {
        // Show result only where contactor is equal to payload.tester
        // also testers can only see published jobs
        q = query(
          collection(db, "jobs"),
          where("date", ">", payload.dateFrom),
          where("date", "<", payload.dateTo),
          where("contractor.id", "==", payload.contractor),
          where("published", "==", true),
          orderBy("date")
        );
      } else {
        q = query(
          collection(db, "jobs"),
          where("date", ">", payload.dateFrom),
          where("date", "<", payload.dateTo),
          orderBy("date")
        );
      }

      const querySnapshot = await getDocs(q);
      let _jobs = []
      querySnapshot.forEach(async (doc) => {
        _jobs.push({ ...doc.data(), id: doc.id });
        this.rowData = await this.formatJob(_jobs, false);
        // This is for mobile view
        this.rowData = await this.organiseTimes();
        const a = Object.values(this.rowData);
        // this.rowData = await this.formatJob(a);
      });

    }
      this.loading = false;
      // console.log(":_jobs", _jobs)
    },

    formatJob(arr, skipUniqs) {
      return new Promise(async (resolve, reject) => {
        let a = [];
        for (let i = 0; i < arr.length; i++) {
          
          let _date = "";
          if (arr[i].date && arr[i].date.seconds) _date = new Date(arr[i].date.seconds * 1000);
          else _date = new Date(arr[i].date);

          let _formatAddress = arr[i].formattedAddress || "";
          

          const payload = {
            projectId: arr[i].projectId,
            projectName: arr[i].projectName,
            phone: arr[i].phone,
            contact: arr[i].contact,
            email: arr[i].email,
            date: this.$moment(_date).format("DD MMM"),
            time: this.$moment(_date).format("HH:mm A"),
            client: arr[i].client,
            customerId: arr[i].customerId,
            customer: arr[i].company,
            fullAddress: _formatAddress || "",
            address: arr[i].address || "",
            geopoint: arr[i].geopoint || "",
            geohash: arr[i].geohash || "",
            mixCode: arr[i].mixCode,
            meters: arr[i].meters,
            id: arr[i].id,
            status: arr[i].status,
            requirements: arr[i].requirements,
            pourLocation: arr[i].pourLocation,
          notesForTester: arr[i].notesForTester,
            tester: null,
            testerNotes: arr[i].testerNotes,
            testerPhone: null,
            notes: arr[i].notes,
            published: arr[i].published,
          };

          // Add tester if it exists
          if (arr[i].contractor && arr[i].contractor.name) {
            payload.tester = arr[i].contractor.name;
            payload.testerPhone = arr[i].contractor.phone;
          }
          a.push(payload);
        }

        resolve(a);
      });
    },
    organiseTimes() {
      return new Promise(async (resolve, reject) => {
        const groupedJobs = this.rowData.reduce((result, job) => {
          // const _date = new Date(job.date + " " + job.time)
          let time = this.$moment(job.date + " " + job.time, "DD MMM hh:mm A");
          time = time.format("HH:mm A");
          // alert(time)
          if (!result[time]) {
            result[time] = { time, jobs: [] };
          }
          result[time].jobs.push(job);

          return result;
        }, {});

        resolve(groupedJobs);
      });
    },
  },
  created() {
    this.activateListner();
  },
};
</script>

<style lang="postcss">
.bgyellowstripes {
  background-color: #ffecad;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffa200' fill-opacity='0.11' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}
.bggreenstripes {
  background-color: #adffe0;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2300ff7c' fill-opacity='0.11' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}
.bgredstripes {
  background-color: #a42020;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ff3100' fill-opacity='0.11' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}
</style>