
          
          <template>
            <div class="w-full bg-white shadow">
          
              <div class="w-full bg-gray-900">
                <div class="container mx-auto h-40 items-center flex">
                  
                  <div class="flex-grow text-left">
                  
                    <h1 class="ml-4 whitespace-nowrap text-white header-heavy text-3xl tracking-tight">
            Job Schedule
          </h1>
                    <div v-if="jobs.length > 0" class="w-full flex mt-4">
                      <!-- <div class="w-16 border-r border-gray-700 text-center">
                        <div class="header-heavy text-sm w-full text-white">
                          {{ jobs.filter((i) => i.status == "FIRM").length }}
                        </div>
                        <div class="header text-xs w-full text-primary">FIRM</div>
                      </div>
                      <div class="w-20 border-r border-gray-700 text-center">
                        <div class="header-heavy text-sm w-full text-white">
                          {{ jobs.filter((i) => i.status == "ON-CALL").length }}
                        </div>
                        <div class="header text-xs w-full text-yellow-500">ON CALL</div>
                      </div> -->
                      <!-- <div class="w-32 text-center">
                        <div class="header-heavy text-sm w-full text-white">
                          {{ jobs.filter((i) => i.tester).length }}
                        </div>
                        <div class="header text-xs w-full text-red-600 uppercase">
                          Unscheduled
                        </div>
                      </div> -->
                    </div>
                  </div>
                  
          
                  <div class="flex-grow flex flex-wrap ml-auto justify-end">
          
                    <!-- <div class="flex-shrink ml-auto mr-6">
                          <input v-if="currentUser.role == 'admin'" type="text" v-model="search" placeholder="Start typing..." class="w-96 rounded text-left px-4 text-base h-12 bg-gray-800 outline-none px-2 text-gray-300">
                        </div> -->
          
                    <div class=" flex-shrink ">
                      <div
                        @click="toggleView()"
                        :class="mapView ? 'bg-blue-600' : 'bg-gray-400'"
                        class="rounded cursor-pointer text-white h-12 px-4 items-center justify-center flex"
                      >
                        <i class="fa-solid fa-map"></i>
                      </div>
                    </div>
          
               
                      <div class="flex-shrink ml-4 ">
                    <div @click="toggleExport()"
                        class="rounded bg-secondary cursor-pointer text-white h-12 px-4 items-center justify-center flex"
                      >
                      
                      <i class="fa-solid fa-file-csv mr-2 "></i> Export Jobs
                      </div>
                  </div>
                  <div v-if="currentUser.role == 'admin'"  class="flex-shrink ml-4 ">
                    <router-link :to="{name: 'Jobs'}"
                        class="rounded bg-primary cursor-pointer text-white h-12 px-4 items-center justify-center flex"
                      >
                        <svg
                          class="w-6 h-6 inline-block"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          ></path>
                        </svg>
                        Add Job
                    </router-link>
                  </div>
                  
          
                    <!-- <div class="flex-shrink">
                      <div class="rounded bg-blue-600 cursor-pointer text-white h-12 px-4 items-center justify-center flex">
                        Publish All
                      </div>
                    </div> -->
          
          
                    <div class="ml-auto block w-full justify-end mt-2 flex">
                      <div class="flex">
                        
                        <div class="flex-grow" v-if="date">
                          <div class="flex w-full items-center space-x-2">
                            
                            <div class="flex-shrink flex items-center text-xs text-gray-200" v-if="prevDate">
                              From
                              <datetime
                                v-model="prevDate"
                                type="date"
                                input-class="rounded text-center h-12 ml-2 bg-gray-800 outline-none px-2 w-40 text-base text-gray-300"
                                class="theme-orange"
                              ></datetime>
                            </div>
                            <div class="flex-shrink flex items-center text-xs text-gray-200" v-if="date">
                              To
                              <datetime
                                v-model="date"
                                type="date"
                                input-class="rounded text-center h-12 ml-2 bg-gray-800 outline-none px-2 w-40 text-base text-gray-300"
                                class="theme-orange"
                              ></datetime>
                            </div>
                            <div class="flex-shrink text-white" @click="previousDate()">
                              <div
                        class="rounded bg-secondary cursor-pointer hover:bg-primary text-white h-12 px-4 items-center justify-center flex"
                      >
                              <i class="fa-solid fa-chevron-left cursor-pointer mr-2"></i> 
                              </div>
                            </div>
                            <div class="flex-shrink text-white" @click="nextDate()">
                              <div
                        class="rounded bg-secondary cursor-pointer hover:bg-primary text-white h-12 px-4 items-center justify-center flex"
                      >
                                <i class="ml-2 fa-solid fa-chevron-right cursor-pointer"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <script>
          import { Datetime } from "vue-datetime";
          import "vue-datetime/dist/vue-datetime.css";
          import { bus } from "../../main";
          export default {
            props: ['jobs', 'mapView'],
            data() {
              return {
                search: "",
                date: "",
                prevDate: ""
              };
            },
            computed: {
              currentUser() {
                return this.$store.state.currentUser;
              },
              currentStartDate() {
                return this.$store.state.currentStartDate;
              },
              currentEndDate() {
                return this.$store.state.currentEndDate;
              },
            },
            watch: {
              search(){
                bus.$emit("search", this.search);
              },
              date(newVal, oldVal) {
                
                if(newVal){
                  bus.$emit("changeDate", {prevDate: this.prevDate, date: this.date});
                }
              },
              prevDate(newVal, oldVal) {
                if(!oldVal){
                  bus.$emit("changeDate", {prevDate: this.prevDate, date: this.date});
                }
              },
            },
            methods: {
              
              nextDate() {
                
                this.date = this.$moment(this.date).add(1, "days").toISOString();
                this.prevDate = this.$moment(this.prevDate).add(1, "days").toISOString();
                this.$store.commit("updateState", {state: "currentStartDate", body: this.prevDate})
                this.$store.commit("updateState", {state: "currentEndDate", body: this.date})
              },
              previousDate() {
                this.date = this.$moment(this.date).subtract(1, "days").toISOString();
                this.prevDate = this.$moment(this.prevDate).subtract(1, "days").toISOString();
                this.$store.commit("updateState", {state: "currentStartDate", body: this.prevDate})
                this.$store.commit("updateState", {state: "currentEndDate", body: this.date})
              },
              togglePage(i) {
                bus.$emit("togglePage", { state: i });
              },
              toggleExport(){
                bus.$emit("triggerExport", true)
              },
              toggleImport() {
                bus.$emit("showImportProjectFunc");
              },
              navigateMenu(i) {
                this.showSlideMenu = false;
                bus.$emit("navigateMenu", i);
                this.showSlideMenu = false;
              },
              toggleView() {
                bus.$emit("toggleMapView");
              },
            },
          
            components: {
              datetime: Datetime,
            },
            created() {
              console.log("currentStartDate", this.currentStartDate)
              this.date = this.$moment().add(11, "hours").toISOString();
              this.prevDate = this.$moment().add(11, "hours").toISOString();
              if(this.currentStartDate) this.date =  this.$moment(this.currentStartDate).add(11, "hours").toISOString();
              if(this.currentEndDate) this.prevDate = this.$moment(this.currentEndDate).add(11, "hours").toISOString();
              
            },
          };
          </script>
          