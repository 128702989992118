import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ClientBook from '../views/ClientBook.vue'
import CallLog from '../views/CallLog.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import Onboarding from '../views/Onboarding.vue'
import Invite from '../views/Invite.vue'
import Jobs from '../views/Jobs.vue'
import ClientPortal from '../views/ClientPortal.vue'
import Users from '../views/Users.vue'
import Dashboard from '../views/Dashboard.vue'
import Test from '../views/Test.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/client-portal/:id',
    name: 'ClientPortal',
    component: ClientPortal
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/client-book',
    name: 'ClientBook',
    component: ClientBook
  },
  {
    path: '/call-log',
    name: 'CallLog',
    component: CallLog
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding
  },
  {
    path: '/invite/:id',
    name: 'Invite',
    component: Invite
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  }
]

const router = new VueRouter({
  routes
})

export default router
