<template>
  <div class="fixed top-0 left-0 w-full h-screen bg-gray-900 bg-opacity-25" style="z-index: 999999999999999;">
  <div class="container mx-auto h-full flex justify-center items-center">
   <div class="h-3/4 w-full">
    <div
    class="relative h-full lg:mt-0 -mt-4 bg-gray-200 rounded-t-xl flex flex-wrap"
  >

  <!-- <div class="z-50 absolute top-0 left-0 m-6 rounded-lg bg-white p-6 shadow-lg">
    {{ noAddress }}
  </div> -->
    <div v-if="!loading" class="flex-grow h-full rounded-lg">
      <div class="w-full bg-secondary h-10 pr-2 pl-10 text-white rounded-t-lg flex items-center">
        <div class="flex-shrink text-lg header-bold">Google Map View</div>
        <div class="flex-shrink ml-auto">
          <div @click="close()" class="h-8 px-4 items-center flex rounded bg-primary hover:bg-gray-900 text-white cursor-pointer">
            close
          </div>
        </div>
      </div>
      <GmapMap
        :center="formatGeo(flattenedJobs[0]) || null"
        :zoom="9"
        style="width: 100%; height: 100%"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in flattenedJobs"
          :position="formatGeo(m) || null"
          :clickable="true"
          :draggable="true"
          @click="center = formatGeo(jobs[0]) || null"
        />
      </GmapMap>
    </div>
  </div>
   </div>
</div>
</div>
</template>

<script>
import { bus } from "../../main";
export default {
  props: ["jobs"],
  data() {
    return {
      loading: true,
      flattenedJobs: [],
      noAddress: []
    };
  },

  methods: {
    close(){
      bus.$emit("toggleMapView", this.rowData);
    },
    formatJobs() {
      this.loading = true
      for (let time in this.jobs) { 
        console.log("this.jobs[time]", this.jobs[time])
        if(this.jobs[time].visible){
        if(this.jobs[time].jobs){
          this.flattenedJobs = [...this.flattenedJobs, ...this.jobs[time].jobs];
          console.log(this.flattenedJobs);
        } else {
          this.flattenedJobs.push(this.jobs[time])
        }
      }
      }
      this.loading = false
    },
    formatGeo(position) {
      console.log("position", position);
      if (
        position.address &&
        position.address.latitude &&
        position.address.longitude
      )
        return {
          lat: position.address.latitude,
          lng: position.address.longitude,
        };
    },
  },
  created() {
    this.formatJobs();
    console.log("jobs", this.jobs);
  },
};
</script>

<style lang="postcss">
.bgyellowstripes {
  background-color: #ffecad;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffa200' fill-opacity='0.11' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}
.bggreenstripes {
  background-color: #adffe0;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2300ff7c' fill-opacity='0.11' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}
.bgredstripes {
  background-color: #a42020;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ff3100' fill-opacity='0.11' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}
</style>