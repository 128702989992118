<template>
  <div class="w-full flex bg-secondary">
    <div class="absolute top-0 left-0 opacity-10 z-0">
      <img
        src="https://images.unsplash.com/photo-1565768502473-c5dc73b7eb33?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        class="w-screen h-screen object-cover"
      />
    </div>
    <div
      class="space-x-10 flex w-full lg:container mx-auto min-h-screen static z-10"
    >
      <div class="w-full lg:w-1/2 min-h-screen flex items-center lg:px-20">
        <div
          class="text-left bg-white lg:rounded-xl w-full shadow-xl h-full lg:h-3/4 p-10 pt-32 lg:pt-20 lg:p-20"
        >
          <img
            src="https://images.squarespace-cdn.com/content/v1/5fc6d3c16879d031da0e1579/1606868635040-DILXV7ATMCTE3V243QTO/kct-logo-main.png?format=1500w"
            class="w-32"
            alt="KCT"
          />
          <h2 class="text-secondary header-heavy tracking-tight text-lg my-6">
            Create a new account
          </h2>

          <form action="">
            <div class="relative z-0 w-full mb-5">
              <input
                v-model="email"
                type="email"
                name="email"
                placeholder=" "
                class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
              />
              <label
                for="email"
                class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
                >Enter email</label
              >
            </div>
            <div class="relative z-0 w-full mb-5">
              <input
                v-model="password"
                :type="showPassword"
                name="password"
                placeholder=" "
                class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
              />
              <label
                for="password"
                class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500"
                >Enter password</label
              >
              <div
                class="absolute top-0 right-0 m-2 text-gray-400 hover:text-primary cursor-pointer"
              >
                <svg
                  v-if="showPassword == 'password'"
                  @click="showPassword = 'text'"
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  ></path>
                </svg>
                <svg
                  v-if="showPassword == 'text'"
                  @click="showPassword = 'password'"
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                  ></path>
                </svg>
              </div>
            </div>

            <div
              @click="submitForm()"
              class="rounded bg-primary cursor-pointer text-white h-12 px-10 items-center justify-center flex"
            >
              Create account
            </div>

            <div class="w-full text-center mt-4 text-sm text-secondary">
              Already have an account?
              <router-link :to="{ name: 'Login' }" class="text-primary"
                >Login</router-link
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { signUp, setFire, getFire } from "../firestore/utils";
export default {
  data() {
    return {
      showPassword: "password",
      loading: false,
      errors: "",
      email: "",
      password: "",
    };
  },
  methods: {
    async submitForm() {
      try {
        this.loading = true;
        const payload = {
          role: "contractor",
          avatar: "",
          active: true,
        };
        const user = await signUp({
          email: this.email,
          password: this.password,
        });
        await setFire({
          collection: "users",
          doc: user.uid,
          body: payload,
        });

        const getUser = await getFire({
          collection: "users",
          doc: user.uid
        })

        console.log("getUser", getUser)
        // Store user as current user in Vuex
        this.$store.commit("updateState", {state: "currentUser", body: getUser})

        // redirect
        this.$router.push({ name: "Onboarding" });
      } catch (e) {
        this.loading = false;
        console.log("e", e);
        this.errors = "Something went wrong";
      }
    },
  },
};
</script>

