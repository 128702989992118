<template>
  <div class="h-full bg-white w-full">
    <div class="w-full bg-gray-900 pt-8 pb-10 relative">
      <h2 class="text-white header-heavy tracking-tight text-xl">
        Import Jobs
      </h2>
    </div>

    <div class="relative -mt-4 bg-gray-200 rounded-t-xl p-2">
      <div class="p-4 w-full">
        <!-- <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone> -->

        <h2 class="my-4 header-heavy text-left">
          Map CSV Fields To The Database
        </h2>

        <input type="file" @change="handleFileUpload" />
    <div v-if="uploadProgress > 0">
      Uploading CSV... Progress: {{ uploadProgress }}%
    </div>

    <div v-for="(csvField, index) in csvFields" :key="index">
      <label>{{ csvField }}</label>
      <select v-model="fieldMapping[csvField]">
        <option value="">Not Mapped</option>
        <option v-for="firestoreField in firestoreFields" :key="firestoreField" :value="firestoreField">
          {{ firestoreField }}
        </option>
      </select>
    </div>

      </div>

      {{ firestoreFields }}
      <div class="w-full">
        <div
          @click="submitForm()"
          class="bg-primary text-white rounded m-2 p-4 header-heavy"
        >
          Start Import
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../main";
import Papa from "papaparse";
import {postFire} from "../../firestore/utils"

export default {
  data() {
    return {
      uploadProgress: 0,
      fieldMapping: {},
      csvFields: [],
      firestoreFields: [], // Replace with your Firestore fields
    }
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0]

      // Parse the CSV file
      Papa.parse(file, {
        header: true,
        complete: (parsedResult) => {
          const csvData = parsedResult.data
          const totalRows = csvData.length
          let processedRows = 0

          // Get the CSV headers
          this.csvFields = Object.keys(csvData[0])

          // Iterate over the parsed CSV data
          csvData.forEach((row) => {
            // Map fields based on fieldMapping object
            const mappedData = {}
            for (const key in row) {
              if (this.fieldMapping[key]) {
                mappedData[this.fieldMapping[key]] = row[key]
              }
            }

            // Save the mapped data to Firestore
            // You can replace this with your Firestore saving logic
            console.log(mappedData)

            // Increment the processed rows count
            processedRows++

            // Calculate progress based on processed rows
            const progress = (processedRows / totalRows) * 100
            this.uploadProgress = progress.toFixed(2)
          })

          // Clear the progress indicator and reset the field mapping
          this.uploadProgress = 0
          this.fieldMapping = {}
        },
        error: (error) => {
          console.error('CSV parsing failed:', error)
        },
      })
    },
  },
}
</script>
