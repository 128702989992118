<template>
  <div class="w-full bg-gray-600 p-4">
    <div v-for="(job, index) in jobs" :key="index" class="p-4">
        <span 
        :class="job.checked || job.address ? 'text-green-300' :'text-white'"
        class="">{{ job.formattedAddress }}</span>
      <vue-google-autocomplete
        :options="autocompleteOptions"
        :value="job.formattedAddress"
        country="au"
        ref="autocomplete"
        classname="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
        placeholder="Project Address"
        :id="index"
        @placechanged="getAddressData"
      >
      </vue-google-autocomplete>
    </div>
  </div>
</template>

<script>
import { setGeoPoint } from "../firebase";
import { getJobs, setFire } from "../firestore/utils";
const geofire = require("geofire-common");
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  data() {
    return {
      jobs: [], // Store the job addresses here
      autocompleteOptions: {
        // Your autocomplete options
      },
      // ...
    };
  },
  methods: {
    onInput(a) {
      console.log("a", a)
    },
    async getAddressData(addressData, placeResultData, id) {
        console.log("index", id)
        console.log("getAddressData", addressData)
        const lat = addressData.latitude;
        const lng = addressData.longitude;
        console.log("Address at latitude", addressData.latitude);
        console.log("Address at longitude", addressData.longitude);
        const geohash = geofire.geohashForLocation([lat, lng]);
        const geopoint = setGeoPoint({
            latitude: addressData.latitude,
            longitude: addressData.longitude,
        });

        console.log("geohash", geohash);
        console.log("geopoint", geopoint);
        console.log("id doc", this.jobs[id].id);
        console.log("payload",  {address: addressData, geohash, geopoint});
        await setFire({collection: 'jobs', doc: this.jobs[id].id, body: {address: addressData, geohash, geopoint}})
        this.$set(this.jobs[id], "checked", true)
    },
    async getJobs() {
      this.jobs = await getJobs();
      console.log("hobs", this.jobs)
    },
  },
  created() {
    this.getJobs();
  },
  components:{
    VueGoogleAutocomplete
  }
};
</script>