import { initializeApp } from 'firebase/app';
import { getFirestore, Timestamp, GeoPoint, geohashForLocation } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";
// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyAgzzzBwnKAnilSbETwkny6ApTz18jlHr4",
    authDomain: "ktc-app-33f33.firebaseapp.com",
    projectId: "ktc-app-33f33",
    storageBucket: "ktc-app-33f33.appspot.com",
    messagingSenderId: "110673050025",
    appId: "1:110673050025:web:d2498c07f0c4f577f02cb8"
  };

const app = initializeApp(firebaseConfig);

// GEO LOCATION USING LIBRARY

export const db = getFirestore(app);
export const auth = getAuth();
export const messaging = getMessaging(app);
export const storage = getStorage();

export const getTimeStamp = function(date){
    return Timestamp.fromDate(date);
}

export const setGeoPoint = function(p){
    return new GeoPoint(p.latitude, p.longitude)
}
export const setGeoHash = function(p){
    return new geohashForLocation(p.latitude, p.longitude)
}