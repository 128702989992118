<template>
    <div :class="reminder.lastCalled ? 'bg-green-100' : 'bg-white'"
    class="w-full flex flex-wrap text-xs mb-1 border bg-white border-gray-100 p-4 rounded-lg"
              > 
              <div class="w-full flex items-center border-b border-gray-100 pb-4 mb-4">
                <div class="flex-shrink text-gray-800 font-bold">
                    <div class="w-10">
                      <input type="checkbox" @change="updateCall(project.reminder.id)" />
                    </div>
                  </div>
                  <div class="flex-grow text-lg text-gray-800 font-bold">
                    <div class="w-full">
                        <!-- <span class="bg-primary text-white rounded text-sm px-3 py-2">CALL AT</span> {{ $moment(project.reminder.reminderDate).format("HH:mmA") }} -->
                        <div class="w-full text-sm font-normal mt-3 ">
                            {{ project.reminder.reminderText }}<br />
                            <div class="mt-2">
                              <span class="bg-gray-100 px-3 py-1 text-sm">
                              <strong>Call At: </strong>{{ $moment(project.reminder.reminderDate.seconds * 1000).format("DD/MM/YYYY HH:mmA") }}
                            </span>
                            </div>
                        </div>
                    </div>
                    
                  </div>
                  <div v-if="reminder && reminder.lastCalled && reminder.lastCalled.seconds" class="flex-shrink">
                    <span class="bg-green-700 text-white rounded-full px-4 py-2">
                      Last Called {{  $moment(reminder.lastCalled.seconds * 1000).format('DD/MM/YYYY HH:mmA') }}
                    </span>
                  </div>
                 
              </div>
                <div class="w-full flex text-left">
                  <div class="flex-grow text-sm text-gray-800 font-bold  flex items-center">
        <div class="w-72 ">
          <h3 class="w-72 truncate"><span class="font-size">Customer: {{ project.jobs[0][0].company }}</span></h3>
          <h2 class="truncate">#{{ project.projectId }} - {{ project.projectName }}</h2> 
        </div>
      </div>
                  
                  

                  <div
     
        class="flex-shrink mr-6 text-gray-800 font-bold border-l border-gray-200 ml-2 flex items-center"
      >
        <span class="font-bold whitespace-nowrap mr-2 ml-2">Site Contact: </span>{{ project.contact }}
      </div>
   
      <div class="pr-6 text-gray-800  flex items-center">
        <a
          :href="'tel:' + project.phone"
          class="text-primary cursor-pointer hover:text-secondary"
          ><i class="fa-solid fa-phone"></i> {{ project.phone }}</a
        >
      </div>
      <div class="flex-shrink text-gray-800 mr-10 flex items-center">
        <!-- <div class="w-40">
        <h3 class="truncate">
          <a
          :href="'mailto:' + project.email"
          class="text-primary cursor-pointer hover:text-secondary"
          ><i class="fa-solid fa-at"></i> {{ project.email }}</a
        >
        </h3>
       </div> -->
      </div>
      <div class="flex-grow justify-end items-center flex text-gray-800">
                      <router-link
          :to="{ name: 'Jobs', query: { jobId: jobId } }"
          class="text-white bg-primary hover:bg-secondary px-3 py-2 rounded cursor-pointer text-sm"
          >View</router-link
        >
           
                  </div>
                </div>

                <div class="w-full border-t border-gray-100 mt-2 pt-2">
      <div class="flex-grow items-center flex text-gray-800">
        <div class="w-56">
          <h3 class="whitespace-nowrap">
            <i class="fa-solid text-blue-600 fa-location-pin mr-1"></i>
        {{ project.projectAddress }}
          </h3>
        </div>
      </div>
    </div>


              </div>
</template>

<script>
import { bus } from "../../main";
import {deleteFire, setFire} from "../../firestore/utils"
import { doc, onSnapshot } from "firebase/firestore";
import { storage, db, auth } from "../../firebase";

export default {
    props:['project', 'jobId'],
    data(){
        return{
            showJobs: false,
            reminder: null
        }
    },
    methods:{

      listenToReminder(){
        const unsub = onSnapshot(doc(db, "reminders", this.project.reminder.id), (doc) => {
            console.log("Current data: ", doc.data());
            this.reminder = {...doc.data(), id: doc.id}
        });
      },
     
      async updateCall(reminder){
       
        await setFire({
            collection: "reminders",
            doc: reminder,
            body: { lastCalled: new Date() },
          });
         // await deleteFire({
        //     collection: 'reminders',
        //     doc: reminder
        // })
       
        bus.$emit('reloadInitiate')
      }
    },
    created(){
      this.listenToReminder()
    }
}
</script>