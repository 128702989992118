<template>
    <div class="bg-white text-left rounded-lg p-6 shadow-lg">
      <h2 class="text-xl font-semibold mb-4">Tester Card</h2>
      <table class="w-full">
        <thead>
          <tr class="w-full h-12">
            <th width="60%" class="text-left"><span class="whitespace-nowrap">Name</span></th>
            <th width="10%" class="text-center"><span class="whitespace-nowrap">Published</span></th>
            <th width="10%" class="text-center"><span class="whitespace-nowrap">Unpublished</span></th>
            <th width="10%" class="text-center"><span class="whitespace-nowrap">Completed</span></th>
            <th width="10%" class="text-center"><span class="whitespace-nowrap">Cancelled</span></th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="testers.length > 0">
          <tr v-for="(tester, index) in testers" :key="index" class="border-b border-gray-100 h-12 w-full text-center">
            <td width="70% ">
                <div class="w-full text-left font-bold">
                {{ tester.name}}
            </div>
            </td>
            <td width="10%" v-if="tester">{{ tester.published }}</td>
            <td width="10%" v-if="tester">{{ tester.unpublished }}</td>
            <td width="10%" v-if="tester"> {{ tester.completed }}</td>
            <td width="10%" v-if="tester">{{ tester.cancelled }}</td>

            <td width="10%" @click="exportToCSV(tester.jobs, tester.name)">
                <div 
                class="rounded bg-secondary cursor-pointer text-white h-10 px-4 items-center justify-center flex"
              >
              
              Export
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import {getJobsByDateAndContractor, getContractors} from "../../firestore/utils"
  import {getTimeStamp} from "../../firebase"
  import { Parser } from "json2csv";
  export default {
    props: ['dateFrom', 'dateTo'],
    data() {
      return {
        testers: [
          
          // Add more tester data here
        ],
      };
    },
    watch:{
      dateFrom(){
        this.testers = []
        this.initiate()
      },
      dateTo(){
        this.testers = []
        this.initiate()
      }
    },
    methods:{
      async initiate(){
        this.testers = await getContractors();
        this.getJobs()
      },
      async getJobs(){
        for (let i = 0; i < this.testers.length; i++) {
          const payload = {
            collection: 'jobs',
            dateFrom:  getTimeStamp(new Date(this.$moment(this.dateFrom).startOf("day"))),
            dateTo: getTimeStamp(new Date(this.$moment(this.dateOf).endOf("day"))),
            contractorId: this.testers[i].id
          }
          console.log("payload tester", payload)
          const _jobs = await getJobsByDateAndContractor(payload)

          this.$set(this.testers[i], 'jobs', _jobs)
          this.$set(this.testers[i], 'published', _jobs.filter(i => i.published).length)
          this.$set(this.testers[i], 'unpublished', _jobs.filter(i => !i.published).length)
          this.$set(this.testers[i], 'completed', _jobs.filter(i => i.status == 'COMPLETED').length)
          this.$set(this.testers[i], 'cancelled', _jobs.filter(i => i.status == 'CANCELLED').length)
          
      }
      },
      formatJob(arr) {
      return new Promise((res, rej) => {
      let a = [];
      for (let i = 0; i < arr.length; i++) {
        let _formatAddress = "";
        if (arr[i].address.street_number)
          _formatAddress = _formatAddress.concat(
            ` ${arr[i].address.street_number}`
          );
        if (arr[i].address.route)
          _formatAddress = _formatAddress.concat(` ${arr[i].address.route},`);
        if (arr[i].address.locality)
          _formatAddress = _formatAddress.concat(` ${arr[i].address.locality}`);
        if (arr[i].address.administrative_area_level_1)
          _formatAddress = _formatAddress.concat(
            ` ${arr[i].address.administrative_area_level_1}`
          );
        if (arr[i].address.postal_code)
          _formatAddress = _formatAddress.concat(
            ` ${arr[i].address.postal_code}`
          );

        let _date = "";
        if (arr[i].date.seconds) _date = new Date(arr[i].date.seconds * 1000);
        else _date = new Date(arr[i].date);

        let payload = {
          projectId: arr[i].projectId,
          projectName: arr[i].projectName,
          date: this.$moment(_date).format("DD MMM"),
          time: this.$moment(_date).format("HH:mm"),
          client: arr[i].client,
          customerId: arr[i].customerId,
          customer: arr[i].company,
          fullAddress: _formatAddress || "",
          address: arr[i].address,
          suburb: arr[i].address.locality,
          mixCode: arr[i].mixCode,
          meters: arr[i].meters,
          id: arr[i].id,
          status: arr[i].status,
          requirements: arr[i].requirements,
          pourLocation: arr[i].pourLocation,
          notesForTester: arr[i].notesForTester,
          poNumber: arr[i].poNumber,
          tester: "",
          email: arr[i].email,
          phone: arr[i].phone,
          contact: arr[i].contact,
          testerPhone: "",
          testerEmail: "",
          notes: arr[i].notes,
          published: arr[i].published,
        };

        // Add tester if it exists
        if (arr[i].contractor) {
          payload.tester = arr[i].contractor.name;
          payload.testerPhone = arr[i].contractor.phone;
          payload.testerEmail = arr[i].contractor.email;
        }
        console.log("payload", payload)
        a.push(payload);
      }

      this.rowData = a;

      
      res()  
    })
    },
    exportToCSV(jobs, testerName) {
      if(jobs.length == 0) alert('There is no data to export for these dates')
      // Convert object array to CSV format
      const json2csvParser = new Parser();
      const csvData = json2csvParser.parse(jobs);

      // Create a Blob object with CSV data
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a temporary anchor element
      const anchor = document.createElement("a");
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = `${testerName}_${this.dateFrom}_to_${this.dateTo}.csv`;

      // Programmatically click the anchor element to trigger the download
      anchor.click();

      // Clean up the temporary anchor element
      window.URL.revokeObjectURL(anchor.href);
      anchor.remove();
    },
    },
    created(){
      this.initiate()
    }
  };
  </script>
  