<template>
  <div class="w-full bg-gray-900 py-10 relative">



    <!-- Slide Menu  -->
    <transition name="slide">
    <div
      v-if="showSlideMenu" style="z-index: 999999999999999"
      class="fixed top-0 left-0 z-50 h-screen w-full flex justify-end"
    >
      <div
        class="p-10 h-screen w-11/12 bg-secondary text-white text-left relative"
      >
        <div class="absolute top-0 right-0 m-2">
          <svg
            @click="showSlideMenu = false"
            class="w-8 h-8 cursor-pointer hover:text-primary cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </div>
        <h2 class="text-white header-bold text-xl text-gray-400 mt-10">
          Admin
        </h2>
        <div class="w-full ml-4 mt-2 flex flex-wrap">
          <div @click="navigateMenu({name: 'Home', target: 'showCustomersCardFunc', value: true})"
            class="w-full flex items-center h-10 text-white text-lg hover:text-primary cursor-pointer"
          >
            Job Schedule
            <svg
              class="ml-auto w-4 h-4 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </div>
        </div>

        <div class="w-full ml-4 mt-2 flex flex-wrap">
          <div @click="navigateMenu({name: 'ClientBook', target: 'showContractorCardFunc', value: true})"
            class="w-full flex items-center h-10 text-white text-lg hover:text-primary cursor-pointer"
          >
            Client Book
            <svg
              class="ml-auto w-4 h-4 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </div>
          <div @click="navigateMenu({name: 'ClientLog', target: 'showContractorCardFunc', value: true})"
            class="w-full flex items-center h-10 text-white text-lg hover:text-primary cursor-pointer"
          >
            Client Log
            <svg
              class="ml-auto w-4 h-4 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </div>
          <div @click="navigateMenu({name: 'Users', target: 'showContractorCardFunc', value: true})"
            class="w-full flex items-center h-10 text-white text-lg hover:text-primary cursor-pointer"
          >
            Users
            <svg
              class="ml-auto w-4 h-4 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </div>
          <div @click="logout()"
            class="w-full flex items-center h-10 text-white text-lg hover:text-primary cursor-pointer"
          >
            Logout
            <svg
              class="ml-auto w-4 h-4 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </div>
          <!-- <div
            class="w-full flex items-center h-10 text-white text-lg hover:text-primary cursor-pointer"
          >
            Add New Tester
            <svg
              class="ml-auto w-4 h-4 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </div> -->
        </div>

   
      </div>
    </div>
    </transition>
    <!-- Finish slide menu  -->
    <div 
    :class="currentUser.role == 'admin' ? ' m-6' : 'text-center w-full mt-6'"
    class="absolute top-0 left-0 text-white header-bold">Welcome to KCT Job Board</div>
    <!-- <svg
      class="absolute top-0 left-0 m-4 w-6 h-6 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
        clip-rule="evenodd"
      ></path>
    </svg> -->

    <svg v-if="currentUser.role == 'admin'"
      @click="showSlideMenu = true"
      class="absolute top-0 right-0 m-4 w-6 h-6 text-white"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 6h16M4 10h16M4 14h16M4 18h16"
      ></path>
    </svg>

    <!-- <div class="px-6 mb-4 mt-6">
                <input v-if="currentUser.role == 'admin'" type="text" v-model="search" placeholder="Start typing..." class="w-full rounded text-left px-4 text-base h-12 bg-gray-800 outline-none px-2 text-gray-300">
              </div> -->

    <div class="flex w-full items-center px-10 mt-10">
      <div class="flex-shrink bg-gray-700 rounded-l text-white w-20 h-20 flex items-center justify-center" @click="previousDate()">
        <i class="fa-solid fa-chevron-left"></i>
      </div>
      <div class="flex-grow" v-if="date">
        <!-- <datetime
              v-model="date"
              type="date"
              input-class="rounded text-center text-lg h-12 bg-gray-800 outline-none px-2 text-gray-300"
              class="theme-orange"
            ></datetime> -->

            <flat-pickr
              class=" text-center text-lg h-20 bg-gray-800 outline-none px-2 text-gray-300"
              :config="config"
              v-model="date"
            ></flat-pickr>

      </div>
      <div class="bg-gray-700 rounded-r  flex-shrink text-white w-20 h-20 flex items-center justify-center" @click="nextDate()">
        <i class="fa-solid fa-chevron-right"></i>
      </div>
    </div>
    

   
  </div>
</template>

<script>
import {signMeOut} from "../../firestore/utils"
import { bus } from '../../main'
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  props: ['jobs'],
  data() {
    return {
      search: "",
      date: "",
      prevDate: "",
      showSlideMenu: false,
      config: {
        enableTime: false,
        // altFormat: "M d",
        altInput: true,
        allowInput: false,
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  watch: {
    search(){
      bus.$emit("search", this.search);
    },
    date() {
      bus.$emit("changeDate", {prevDate: this.prevDate, date: this.date});
    },
    prevDate() {
      bus.$emit("changeDate", {prevDate: this.prevDate, date: this.date});
    },
  },
  methods: {
    async logout(){
        await signMeOut()
        this.$router.push({name: 'Login'})
      },
    nextDate() {
      this.date = this.$moment(this.date).add(1, "days").toISOString();
      this.prevDate = this.$moment(this.prevDate).add(1, "days").toISOString();
    },
    previousDate() {
      this.date = this.$moment(this.date).subtract(1, "days").toISOString();
      this.prevDate = this.$moment(this.prevDate).subtract(1, "days").toISOString();
    },
    togglePage(i) {
      bus.$emit("togglePage", { state: i });
    },
    toggleExport(){
      bus.$emit("triggerExport", true)
    },
    toggleImport() {
      bus.$emit("showImportProjectFunc");
    },
    navigateMenu(i) {
      this.showSlideMenu = false;
      this.$router.push({name: i.name})
      this.showSlideMenu = false;
    },
    toggleView() {
      bus.$emit("toggleMapView");
    },
  },

  components: {
    datetime: Datetime,
    flatPickr
  },
  created() {
    console.log("jobs!", this.jobs)
    this.date = this.$moment().add(11, "hours").toISOString();
    this.prevDate = this.$moment().add(11, "hours").toISOString();
  },
};
</script>

<style lang="postcss">
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(200%, 0);
}
</style>
