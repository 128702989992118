<template>
  <div id="app">
    <div v-if="isOffline" class="h-screen w-full fixed bg-gray-900 bg-opacity-50 flex items-center justify-center" style="z-index: 99999999999">
      <div class="w-72 h-20 rounded-xl bg-white flex items-center justify-center">
        <i class="fa-solid fa-circle-notch animate-spin mr-2 text-primary"></i>
         You Are currently offline...
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOffline: !navigator.onLine
    };
  },
  created() {
    window.addEventListener('offline', this.handleOfflineStatus);
    window.addEventListener('online', this.handleOfflineStatus);
  },
  destroyed() {
    window.removeEventListener('offline', this.handleOfflineStatus);
    window.removeEventListener('online', this.handleOfflineStatus);
  },
  methods: {
    handleOfflineStatus() {
      this.isOffline = !navigator.onLine;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
