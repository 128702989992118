<template>
  <div>
    <nav-menu />
    <div class="w-full bg-gray-900">
      <div class="container mx-auto h-40 items-center flex">
        <div class="flex-shrink text-left">
          <h1 class="text-white header-heavy text-3xl tracking-tight">
            User Management
          </h1>
        </div>

        <div class="flex-grow flex flex-wrap ml-auto">
          <div class="flex-shrink ml-auto mr-6">
            <div
              @click="showCreateModal = true"
              class="rounded bg-primary cursor-pointer text-white h-12 px-4 items-center justify-center flex"
            >
              <svg
                class="w-6 h-6 inline-block"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                ></path>
              </svg>
              Add User
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="min-w-full bg-white">
      <thead>
        <tr class="bg-secondary text-gray-300 text-sm font-normal">
          <th class="py-2 px-4 border-b border-gray-200 text-left">Name</th>
          <th class="py-2 px-4 border-b border-gray-200 text-left">Email</th>
          <th class="py-2 px-4 border-b border-gray-200 text-left">Phone</th>
          <th class="py-2 px-4 border-b border-gray-200">Role</th>
          <th class="py-2 px-4 border-b border-gray-200">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id" class="h-10 bg-gray-100">
          <td class="px-4 border-b border-gray-200 text-left font-bold">
            {{ user.name }}
          </td>
          <td
            class="px-4 border-b border-gray-200 text-left text-sm text-gray-500"
          >
            {{ user.email }}
          </td>
          <td
            class="px-4 border-b border-gray-200 text-left text-sm text-gray-500"
          >
            {{ user.phone }}
          </td>
          <td class="px-4 border-b border-gray-200">
            <select
              class="h-10 bg-gray-700 rounded text-white text-sm px-2"
              v-model="user.role"
              @change="updateUserRole(user)"
            >
              <option value="admin">Admin</option>
              <option value="contractor">Remote Tester</option>
            </select>
          </td>
          <td class="py-2 px-4 border-b border-gray-200">
            <button
              @click="openEditModal(user)"
              class="mr-2 bg-primary hover:bg-blue-700 text-white text-sm font-bold py-1 px-4 rounded"
            >
              Edit
            </button>
            <button
              @click="sendResetPasswordEmail(user)"
              class="bg-red-500 hover:bg-red-700 text-white text-sm font-bold py-1 px-4 rounded"
            >
              Reset Password
            </button>
            <i @click="deleteProject(user)" class="ml-10 fa-solid fa-xmark text-red-600 cursor-pointer hover:text-red-800"></i>
          </td>

        </tr>
      </tbody>
    </table>

    <!-- Edit User Modal -->
    <div
      v-if="showEditModal"
      class="fixed z-50 inset-0 flex items-center justify-center"
    >
      <div class="absolute inset-0 z-10 bg-gray-500 opacity-75"></div>
      <div class="bg-white z-20 rounded-lg shadow-lg p-8 w-96">
        <h2 class="text-lg font-bold mb-4">Edit User</h2>
        <form class="text-left">
          <div class="mb-4">
            <label for="editName" class="block">Name</label>
            <input
              v-model="editUser.name"
              type="text"
              id="editName"
              class="rounded h-12 border border-gray-400 px-2 py-1 w-full"
            />
          </div>
          <div class="mb-4">
            <label for="editEmail" class="block">Email</label>
            <input
              v-model="editUser.email"
              type="email"
              id="editEmail"
              class="rounded h-12 border border-gray-400 px-2 py-1 w-full"
            />
          </div>
          <div class="mb-4">
            <label for="editPhone" class="block">Phone</label>
            <input
              v-model="editUser.phone"
              type="text"
              id="editPhone"
              class="rounded h-12 border border-gray-400 px-2 py-1 w-full"
            />
          </div>
          <div class="mb-4">
            <label for="editRole" class="block">Role</label>
            <select
              v-model="editUser.role"
              id="editRole"
              class="rounded h-12 border border-gray-400 px-2 py-1 w-full"
            >
              <option value="admin">Admin</option>
              <option value="contractor">Contractor</option>
            </select>
          </div>
          <div class="flex justify-end">
            <button
              type="button"
              @click="closeEditModal"
              class="mr-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="button"
              @click="saveUserChanges"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>

    <div
      v-if="showCreateModal"
      class="fixed z-50 inset-0 flex items-center justify-center"
    >
      <div class="absolute inset-0 z-10 bg-gray-500 opacity-75"></div>
      <div class="bg-white z-20 rounded-lg shadow-lg p-8 w-96">
        <h2 class="text-lg font-bold mb-4">Create User</h2>
        <form class="text-left">
          <div class="mb-4">
            <label for="editEmail" class="block">Name</label>
            <input
              v-model="name"
              type="text"
              id="editEmail"
              class="rounded h-12 border border-gray-400 px-2 py-1 w-full"
            />
          </div>
          <div class="mb-4">
            <label for="editEmail" class="block">Email</label>
            <input
              v-model="email"
              type="email"
              id="editEmail"
              class="rounded h-12 border border-gray-400 px-2 py-1 w-full"
            />
          </div>
          <div class="mb-4">
            <label for="editEmail" class="block">Password</label>
            <input
              v-model="password"
              type="password"
              id="editEmail"
              class="rounded h-12 border border-gray-400 px-2 py-1 w-full"
            />
          </div>

          <div class="mb-4">
            <label for="editPhone" class="block">Phone</label>
            <input
              v-model="phone"
              type="text"
              id="editPhone"
              class="rounded h-12 border border-gray-400 px-2 py-1 w-full"
            />
          </div>
          <div class="mb-4">
            <label for="editRole" class="block">Role</label>
            <select
              v-model="role"
              id="editRole"
              class="rounded h-12 border border-gray-400 px-2 py-1 w-full"
            >
              <option value="admin">Admin</option>
              <option value="contractor">Remote Tester</option>
            </select>
          </div>

          <div class="flex justify-end">
            <button
              type="button"
              @click="showCreateModal = false"
              class="mr-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="button"
              @click="createUser(email, password)"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
  <script>
import NavMenu from "@/components/headers/navMenu";

import {
  getUsers,
  setFire,
  resetPassword,
  signUp,
  deleteFire
} from "../firestore/utils"
export default {
  data() {
    return {
      name: "",
      email: "",
      password: "",
      role: "contractor",
      phone: "",
      users: [],
      showEditModal: false,
      editUser: {},
      showCreateModal: false,
    };
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    async deleteProject(user){
      if (window.confirm('Are you sure you want to delete?')) {
      await deleteFire({collection: "users", doc: user.id});
      this.loadUsers()
      } else {
        
      }
    },
    async createUser(email, password) {
      try {
        const user = await signUp({
          email: email,
          password: password});
        console.log("User created:", user);
        await setFire({
          collection: "users",
          doc: user.uid,
          body: {
            email: this.email,
            role: this.role,
            phone: this.phone,
            name: this.name,
            active: true,
            avatar: "",
            password: this.password
          },
        });

        this.email = ''
        this.name = ''
        this.password = ''
        this.phone= ''

        this.showCreateModal = false
        this.loadUsers()
        // Perform any additional operations or updates after user creation
      } catch (error) {
        console.error("Error creating user:", error);
      }
    },
    async loadUsers() {
      // Load users from Firebase Firestore collection
      this.users = await getUsers();
    },
    openEditModal(user) {
      this.editUser = { ...user };
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    async saveUserChanges() {
      // Update user data in Firebase Firestore
      await setFire({
        collection: "users",
        doc: this.editUser.id,
        body: this.editUser,
      });

      this.closeEditModal();
    },
    async sendResetPasswordEmail(user) {
      console.log("user", user);
      // Send reset password email to user
      await resetPassword({
        email: user.email,
      });

      alert("Password reset link has been sent to this user");
    },
    async updateUserRole(user) {
      // Update user role in Firebase Firestore
      await setFire({
          collection: "users",
          doc: user.id,
          body: {
            role: user.role,
          },
        });

      
    },
  },
  components: {
    NavMenu,
  },
};
</script>
  