<template>
  <div class="fixed top-0 right-0 z-50 h-screen shadow-lg bg-white w-full sm:w-96">


    <div class="relative -mt-4 bg-gray-200 rounded-t-xl h-full p-2">
      <div class="p-4 w-full">
        <div class="w-full flex items-center">
          <div class="flex-grow text-left my-6 text-lg font-bold tracking-tight">
          <h2>Create a customer</h2>
        </div>
        <div class="flex-shrink cursor-pointer hover:text-primary" @click="close()"><i class="fa-solid fa-xmark"></i></div>
        </div>
        <div class="grid grid-cols-12 gap-4 mt-2">
          <div class="col-span-4 h-full items-center flex text-sm whitespace-nowrap">Company</div>
          <div class="col-span-8 h-full items-center flex">
            <input 
              v-model="company" placeholder="ie: ABC Company"
              type="text"
              class="rounded h-12 bg-white outline-none px-2 text-gray-600"
            />
          </div>
         
        </div>
      </div>

      <div class="w-full">
   
          <div @click="submitForm()" class="bg-primary hover:bg-secondary cursor-pointer text-white rounded m-2 p-4 header-heavy">
            Save Customer
          </div>

      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '../../main'
import {postFire, setFire} from "../../firestore/utils"
export default {
  props:['customer'],
  data() {
    return {
      company: "",
    };
  },
  methods: {
    async submitForm(){
        const payload = {
            company: this.company,
            jobs: []
        }

        let result 
        if(!this.customer){
         result = await postFire({
            collection: 'customers',
            body: payload
        })
      } else {
        result = await setFire({
            collection: 'customers',
            doc: this.customer.id,
            body: payload
        })
      }

        bus.$emit('closeCreateCustomer', {status: false, customer: result})
        bus.$emit('openClientCardLarge', result)
    },
    close(){
      bus.$emit('closeCreateCustomer', {status: false, customer: null})
    }
  },
  created(){
    if(this.customer){
      this.company = this.customer.company
      this.callFrequency = this.customer.callFrequency
    }
  }
};
</script>
