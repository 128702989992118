<template>
  <div class="w-full">
  <div class="bg-white rounded-lg p-6 shadow-lg">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-semibold">Jobs By Date</h2>
      <p class="text-gray-400 text-sm">From:{{ dateStart }} until {{ dateEnd }}</p>
    </div>


    <div class="w-full flex text-left">
      <div class="flex-grow font-bold text-gray-900">Projects:</div>
      <div class="flex-shrink text-lg font-bold text-gray-900 mr-2">{{ metrics.projects.length }}</div>

    </div>
    <div class="w-full flex text-left border-dashed pt-2">
      <div class="flex-grow font-bold text-gray-900">Total Jobs:</div>
      <div class="flex-shrink text-lg font-bold text-gray-900 mr-2">{{ metrics.totalJobs }}</div>
    </div>
    <div class="w-full flex text-left border-t border-gray-200 border-dashed pt-2 mt-2">
      <div class="flex-grow font-bold text-green-600">Completed Jobs:</div>
      <div class="flex-shrink text-lg font-bold text-gray-900 mr-2">{{completedJobs().length }}</div>
    </div>
    <div class="w-full flex text-left">
      <div class="flex-grow font-bold text-green-400">Firm Jobs:</div>
      <div class="flex-shrink text-lg font-bold text-gray-900 mr-2">{{firmJobs().length }}</div>
    </div>
    <div class="w-full flex text-left">
      <div class="flex-grow font-bold text-yellow-500">On-Call Jobs:</div>
      <div class="flex-shrink text-lg font-bold text-gray-900 mr-2">{{ onCallJobs().length }}</div>

    </div>
    <div class="w-full flex text-left">
      <div class="flex-grow font-bold text-red-600">Cancelled Jobs:</div>
      <div class="flex-shrink text-lg font-bold text-gray-900 mr-2">{{ cancelledJobs().length }}</div>
    </div>

    <div class="w-full flex mt-4">

      <div @click="exportToCSV()"
              class="rounded bg-secondary cursor-pointer text-white h-12 px-4 items-center justify-center flex"
            >
            
            <i class="fa-solid fa-file-csv mr-2 "></i> Export Jobs
            </div>
    </div>

    
    <!-- Add other metrics here -->
  </div>
</div>
</template>
  
  <script>
  import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { getTimeStamp, db } from "../../firebase";
  import { Parser } from "json2csv";
let unsubscribe
export default {
  props:['metrics', 'dateStart', 'dateEnd'],
  data() {
    return {
      rowData: [],
      totalActiveProjects: 0, // Assign the actual value here
      totalJobsCompleted: 0, // Assign the actual value here
      // Add other metrics data here

      showArrows: true, // Set this to false if you don't want to show arrows
    };
  },
  watch:{
    dateStart(){
      this.initiate()
    }
  },
  computed: {
    totalActiveProjectsChange() {
      // Calculate the change from the previous day for totalActiveProjects
      // Replace the following line with your actual calculation logic
      return Math.floor(Math.random() * 21) - 10; // Random change between -10 and 10 for demonstration
    },
    // Add computed properties for other metrics changes in a similar manner
  },
  methods:{
    completedJobs(){
      return this.rowData.filter(i => i.status == 'COMPLETED')
    },
    firmJobs(){
      console.log("this.rowData.filter(i => i.status == 'FIRM')", this.rowData.filter(i => i.status == 'FIRM'))
      return this.rowData.filter(i => i.status == 'FIRM')
    },
    onCallJobs(){
      return this.rowData.filter(i => i.status == 'ON-CALL')
    },
    cancelledJobs(){
      return this.rowData.filter(i => i.status == 'CANCELLED')
    },
    initiate(){
this.rowData = []
      const start = this.$moment(this.dateStart).add(11, "hours").startOf("day");
        const end = this.$moment(this.dateEnd).add(11, "hours").endOf("day");

        let payload = {
          collection: "jobs",
          dateFrom: getTimeStamp(new Date(start)),
          dateTo: getTimeStamp(new Date(end)),
        };

        let q = query(
            collection(db, "jobs"),
            where("date", ">", payload.dateFrom),
            where("date", "<", payload.dateTo),
            orderBy("date")
          );
        

          unsubscribe = onSnapshot(q, (snapshot) => {
          let _jobs = [];
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              _jobs.push({ ...change.doc.data(), id: change.doc.id });
              this.formatJob(_jobs);
            }
          });
        });

    },
    formatJob(arr) {
      return new Promise((res, rej) => {
      let a = [];
      for (let i = 0; i < arr.length; i++) {
        let _formatAddress = "";
        if (arr[i].address && arr[i].address.street_number)
          _formatAddress = _formatAddress.concat(
            ` ${arr[i].address.street_number}`
          );
        if (arr[i].address && arr[i].address.route)
          _formatAddress = _formatAddress.concat(` ${arr[i].address.route},`);
        if (arr[i].address && arr[i].address.locality)
          _formatAddress = _formatAddress.concat(` ${arr[i].address.locality}`);
        if (arr[i].address && arr[i].address.administrative_area_level_1)
          _formatAddress = _formatAddress.concat(
            ` ${arr[i].address.administrative_area_level_1}`
          );
        if (arr[i].address && arr[i].address.postal_code)
          _formatAddress = _formatAddress.concat(
            ` ${arr[i].address.postal_code}`
          );

        let _date = "";
        if (arr[i].date.seconds) _date = new Date(arr[i].date.seconds * 1000);
        else _date = new Date(arr[i].date);

        let payload = {
          projectId: arr[i].projectId,
          projectName: arr[i].projectName,
          date: this.$moment(_date).format("DD MMM"),
          time: this.$moment(_date).format("HH:mm"),
          client: arr[i].client,
          customerId: arr[i].customerId,
          customer: arr[i].company,
          fullAddress: _formatAddress || "",
          address: arr[i].address,
          mixCode: arr[i].mixCode,
          meters: arr[i].meters,
          id: arr[i].id,
          status: arr[i].status,
          requirements: arr[i].requirements,
          poNumber: arr[i].poNumber,
          tester: "",
          email: arr[i].email,
          phone: arr[i].phone,
          contact: arr[i].contact,
          testerPhone: "",
          testerEmail: "",
          notes: arr[i].notes,
          published: arr[i].published,
        };

        // Add tester if it exists
        if (arr[i].contractor) {
          payload.tester = arr[i].contractor.name;
          payload.testerPhone = arr[i].contractor.phone;
          payload.testerEmail = arr[i].contractor.email;
        }
        console.log("payload", payload)
        a.push(payload);
      }

      this.rowData = a;

      
      res()  
    })
    },
    exportToCSV() {
      if(this.rowData.length == 0) alert('There is no data to export for these dates')
      // Convert object array to CSV format
      const json2csvParser = new Parser();
      const csvData = json2csvParser.parse(this.rowData);

      // Create a Blob object with CSV data
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a temporary anchor element
      const anchor = document.createElement("a");
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = "currentData.csv";

      // Programmatically click the anchor element to trigger the download
      anchor.click();

      // Clean up the temporary anchor element
      window.URL.revokeObjectURL(anchor.href);
      anchor.remove();
    },
  },
  created(){
    this.initiate()
  }
};
</script>
  
  <!-- Add the following styles to your main CSS file or use a dedicated CSS framework for icons like Font Awesome -->
  <style>
.fa-solid {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.fa-caret-up::before {
  content: "\f0d8";
}
.fa-caret-down::before {
  content: "\f0d7";
}
</style>
  