<template>
  <div class="h-full w-full bg-white">
    <nav-menu />
    <!-- Import Card  -->
    <transition name="slide">
      <div
        v-if="showImportProjectForm"
        class="w-full h-full absolute top-0 left-0 z-50"
      >
        <div
          @click="(showImportProjectForm = false), (showMenu = false)"
          class="bg-white bg-opacity-25 flex items-center justify-center h-12 w-12 rounded m-4 absolute top-0 left-0 z-40"
        >
          <svg
            class="w-8 h-8 text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>

        <import-project-form />
      </div>
    </transition>
    <!-- Add Job Card  -->
    <transition name="">
      <div
        v-if="showAddProjectForm"
        class="w-full h-screen absolute top-0 left-0 z-50"
      >
        <div
          @click="
            (editJob = null), (showAddProjectForm = false), (showMenu = false)
          "
          class="bg-white cursor-pointer bg-opacity-25 flex items-center justify-center h-12 w-12 rounded m-4 absolute top-0 left-0 z-40"
        >
          <svg
            class="w-8 h-8 text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>

        <add-project-form
          :editJob="editJob"
          :date="date"
          :prevDate="prevDate"
        />
      </div>
    </transition>
    <!-- Customers List  -->
    <transition name="slide">
      <show-customers v-if="showCustomers" />
    </transition>
    <!-- Create Customer  -->
    <transition name="slide">
      <div
        v-if="createCustomer"
        class="w-full h-screen absolute top-0 left-0 z-50"
      >
        <div
          @click="showOwner = false"
          class="bg-white bg-opacity-25 flex items-center justify-center h-12 w-12 rounded m-4 absolute top-0 left-0 z-40"
        >
          <svg
            class="w-8 h-8 text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>

        <create-customer v-if="createCustomer" />
      </div>
    </transition>
    <!-- Contractors List  -->
    <transition name="slide">
      <show-contractors
        v-if="showContractors"
        :assigningToJob="selectedJob"
        class="z-50"
      />
    </transition>
    <!-- Owner/Conractor Card  -->
    <transition name="slide">
      <div v-if="showOwner" class="w-full h-screen absolute top-0 left-0 z-50">
        <div
          @click="showOwner = null"
          class="bg-white bg-opacity-25 flex items-center justify-center h-12 w-12 rounded m-4 absolute top-0 left-0 z-40"
        >
          <svg
            class="w-8 h-8 text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>

        <owner-card
          :card="showOwner"
          :selectedJob="selectedJob"
          :date="date"
          :prevDate="prevDate"
        />
      </div>
    </transition>

    <!-- Work Card  -->
    <transition name="slide">
      <div
        v-if="showWorkCard"
        class="w-full h-screen absolute top-0 left-0 z-40"
      >
        <div
          @click="showWorkCard = null"
          class="bg-white bg-opacity-25 flex items-center justify-center h-12 w-12 rounded m-4 absolute top-0 left-0 z-40"
        >
          <svg
            class="w-8 h-8 text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>

        <work-card :card="showWorkCard" />
      </div>
    </transition>

    <!-- Headers  -->
    <main-mobile
      v-if="jobs && togglePage && !showAddProjectForm"
      class="sm:hidden w-full"
      :jobs="jobs"
    />
    <main-desktop class="sm:block w-full hidden" :jobs="jobs" :mapView="map" />

    <!-- Timeline  -->
    <timeline class="sm:hidden"
      v-if="!isLandscape && togglePage && !showAddProjectForm"
      :triggerExport="triggerExport"
      :date="prevDate + '&' + date"
      :search="search"
    />
    <timeline-desktop class="hidden sm:block"
      v-if="isLandscape"
      :triggerExport="triggerExport"
      :date="prevDate + '&' + date"
      :search="search"
    />
    <!-- Customers  -->
    <list-clients v-if="listClients" />

    <!-- Map  -->
    <map-view v-if="map && !showAddProjectForm" :jobs="jobs" />

    <!-- Add Buttons  -->
    <div
      v-if="showMenu && !showAddProjectForm && !showImportProjectForm"
      class="lg:hidden bg-white rounded-full h-96 w-96 bg-opacity-75 fixed bottom-0 right-0 -m-40"
    ></div>
    <router-link
      :to="{ name: 'Jobs' }"
      v-if="
        !showAddProjectForm &&
        !showImportProjectForm &&
        currentUser.role == 'admin'
      "
      class="lg:hidden z-50 realative h-16 bg-primary text-white w-16 fixed bottom-0 right-0 m-4 rounded-full flex items-center justify-center"
    >
      <div v-if="showMenu" class="absolute top-0 right-0 -mt-28">
        <div class="flex w-screen flex-wrap">
          <div class="w-full flex justify-end my-1">
            <div
              class="bg-white rounded-full px-2 py-3 text-gray-600 flex-shrink"
            >
              <svg
                class="w-6 h-6 text-primary inline-block"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                ></path>
              </svg>
              Add Project
            </div>
          </div>
        </div>
      </div>
      <svg
        class="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        ></path>
      </svg>
    </router-link>
  </div>
</template>

<script>
import WorkCard from "@/components/cards/workCard";
import OwnerCard from "@/components/cards/ownerCard";
import AddProjectForm from "@/components/cards/addProjectForm";
import ImportProjectForm from "@/components/cards/importProjectForm";
import MainMobile from "@/components/headers/mainMobile";
import MainDesktop from "@/components/headers/mainDesktop";
import Timeline from "@/components/timeline/timeline";
import TimelineDesktop from "@/components/timeline/timeline-desktop";
import MapView from "@/components/timeline/map";
import showCustomers from "@/components/cards/showCustomers";
import showContractors from "@/components/cards/showContractors";
import CreateCustomer from "@/components/cards/createCustomer.vue";
import ListClients from "@/components/cards/listClients.vue";
import { bus } from "../main";
import NavMenu from "@/components/headers/navMenu";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
export default {
  data() {
    return {
      isLandscape: false,
      editJob: null,
      search: "",
      map: false,
      listClients: false,
      togglePage: true,
      date: "",
      prevDate: "",
      selectedJob: "",
      showMenu: false,
      showSlideMenu: true,
      showWorkCard: null,
      showOwner: null,
      showAddProjectForm: false,
      showImportProjectForm: false,
      showCustomers: false,
      showContractors: false,
      createCustomer: false,
      jobs: [],
      triggerExport: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    async checkSignIn() {
      return new Promise((res, rej) => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            res(user.uid);
          } else {
            this.$router.push({ name: "Login" });
            rej();
          }
        });
      });
    },
  },
  created() {
    this.checkSignIn();
    if (this.$route.query.editProjectsByProjectId)
      this.showAddProjectForm = true;

    bus.$on("showAddProjectFormEvent", (data) => {
      this.showAddProjectForm = true;
      this.showCustomers = false;
    });
    bus.$on("jobs", (data) => {
      this.jobs = data;
    });
    bus.$on("showCustomersCardFunc", (data) => {
      this.showCustomers = data;
      this.showMenu = false;
    });
    bus.$on("showContractorCardFunc", (data) => {
      this.showContractors = data;
      this.showMenu = false;
    });
    bus.$on("loadOwnerCardFunc", (data) => {
      this.showOwner = data;
      this.showMenu = false;
    });
    bus.$on("updateJob", (data) => {
      this.editJob = data;
      this.showAddProjectForm = true;
      this.showMenu = false;
    });
    bus.$on("showAddProjectFunc", (data) => {
      this.editJob = null;
      // this.date = null
      // this.prevDate = nul
      this.showAddProjectForm = false;
      this.showMenu = false;
    });
    bus.$on("showImportProjectFunc", (data) => {
      this.showImportProjectForm = !this.showImportProjectForm;
      this.showMenu = false;
    });
    bus.$on("showWorkCardFunc", (data) => {
      this.showWorkCard = data;
      this.showMenu = false;
    });
    bus.$on("togglePage", (data) => {
      this[data.state] = !this[data.state];
      this.showMenu = false;
    });
    bus.$on("changeDate", (data) => {
      this.date = data.date;
      this.prevDate = data.prevDate;
    });
    bus.$on("toggleMapView", (data) => {
      this.map = !this.map;
    });
    bus.$on("switchOwner", (data) => {
      this.selectedJob = data;
      this.showContractors = !this.showContractors;
    });
    bus.$on("refreshHome", (data) => {
      this.togglePage = false;
      setTimeout(() => (this.togglePage = true), 2000);
    });
    bus.$on("navigateMenu", (data) => {
      bus.$emit(data.target, data.value);
    });
    bus.$on("search", (data) => {
      this.search = data;
    });
    bus.$on("triggerExport", (data) => {
      this.triggerExport = data;
    });

      
    // Listen for changes in orientation
    const mediaQueryList = window.matchMedia("(orientation: landscape)");

    const orientationChangeHandler = (event) => {
      this.isLandscape = event.matches;
    };

    // Add listener for orientation changes
    mediaQueryList.addListener(orientationChangeHandler);

    // Initial check for the orientation
    this.isLandscape = mediaQueryList.matches;
  },
  components: {
    NavMenu,
    WorkCard,
    OwnerCard,
    AddProjectForm,
    ImportProjectForm,
    MainMobile,
    MainDesktop,
    Timeline,
    showCustomers,
    showContractors,
    CreateCustomer,
    MapView,
    ListClients,
    TimelineDesktop,
  },
};
</script>
