<template>
  <div
    class="w-full overflow-x-scroll relative h-full lg:mt-0 -mt-4 bg-gray-200 flex flex-wrap"
  >
    <div
      v-if="showPublishAll"
      class="w-full z-50 flex items-center justify-center h-screen fixed top-0 left-0 bg-secondary bg-opacity-50"
    >
      <div class="w-96 py-20 px-10 rounded-lg p-4 bg-white shadow-lg">
        You are about to publish all jobs at once, are you sure you want to do
        this?
        <div class="w-full flex justify-center space-x-2 mt-10">
          <div class="flex-shrink">
            <div
              @click="showPublishAll = false"
              class="h-14 bg-secondary px-4 cursor-pointer hover:bg-gray-900 text-white rounded flex items-center justify-center"
            >
              No, Go Back
            </div>
          </div>
          <div class="flex-shrink">
            <div
              @click="publishAll()"
              class="h-14 bg-primary px-4 cursor-pointer hover:bg-gray-900 text-white rounded flex items-center justify-center"
            >
              Yes Publish All
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="signOff >= 0"
      class="w-full z-50 h-screen fixed top-0 left-0 bg-secondary bg-opacity-50"
    >
      <div class="w-full h-full relative flex items-center justify-center">
        <div
          class="w-96 relative py-20 px-10 rounded-lg p-4 bg-white shadow-lg"
        >
          <div class="absolute top-0 right-0 m-2">
            <i
              @click="signOff = -1"
              class="fa-solid fa-xmark cursor-pointer text-secondary hover:text-primary"
            ></i>
          </div>
          Please select if you would like to mark the job as completed or
          cancelled.
          <div class="w-full flex justify-center space-x-2 mt-10">
            <div class="flex-shrink">
              <div
                @click="cancelJob()"
                class="h-14 bg-secondary px-4 cursor-pointer hover:bg-gray-900 text-white rounded flex items-center justify-center"
              >
                Cancel Job
              </div>
            </div>
            <div class="flex-shrink">
              <div
                @click="completeJob()"
                class="h-14 bg-primary px-4 cursor-pointer hover:bg-gray-900 text-white rounded flex items-center justify-center"
              >
                Complete Job
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showTesters"
      class="w-full z-40 h-screen fixed top-0 left-0 bg-secondary bg-opacity-50"
    >
      <div class="w-full h-full relative flex items-center justify-center">
        <div
          class="w-96 h-96 overflow-y-scroll relative rounded-lg bg-white shadow-lg"
        >
          <div
            class="h-20 w-full bg-gray-900 text-left px-6 text-white rounded-t-lg flex items-center"
          >
            <div class="flex-grow">
              <h3 class="text-white text-lg">Select a tester</h3>
            </div>
            <div class="flex-shrink ml-auto">
              <i
                @click="showTesters = false"
                class="fa-solid fa-xmark cursor-pointer text-white hover:text-primary"
              ></i>
            </div>
          </div>

          <!-- {{ smsTester.testerPhone }} -->
          <div class="w-full flex px-6 flex-wrap justify-center space-x-2 mt-4">
            <div @click="removeTester(selectedJob)" class="mb-4 w-full h-10 flex items-center justify-center cursor-pointer text-red-600 border border-red-600 bg-red-100 hover:bg-red-800 hover:text-white rounded-lg">
              Remove Tester
            </div>
            <div
              class="w-full"
              v-for="(t, tindex) in testers"
              :key="'tester' + tindex"
            >
           
              <div
                @click="updateTester(t, tindex, selectedJob)"
                class="w-full rounded-lg px-4 hover:bg-gray-100 h-10 cursor-pointer flex items-center text-sm text-gray-600"
              >
                {{ t.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showNotificationPopup.length > 0"
      class="w-full z-40 h-screen fixed top-0 left-0 bg-secondary bg-opacity-50"
    >
      <div class="w-full h-full relative flex items-center justify-center">
        <div
          class="w-96 h-96 overflow-y-scroll relative rounded-lg bg-white shadow-lg"
        >
          <div
            class="h-20 w-full bg-gray-900 text-left px-6 text-white rounded-t-lg flex items-center"
          >
            <div class="flex-grow">
              <h3 class="text-white text-lg">Notify testers</h3>
            </div>
            <div class="flex-shrink ml-auto">
              <i
                @click="showNotificationPopup = []"
                class="fa-solid fa-xmark cursor-pointer text-white hover:text-primary"
              ></i>
            </div>
          </div>

          <!-- {{ smsTester.testerPhone }} -->
          <div class="w-full flex px-6 flex-wrap justify-center space-x-2 mt-4">
            <h2 class="text-sm mb-4">
              Would you like to notify the testers of this change?
            </h2>
            <div
              class="w-full h-10 rounded hover:bg-gray-200 bg-gray-100 mb-2 px-4 flex items-center text-left"
              v-for="(t, index) in showNotificationPopup"
              :key="index"
            >
              <label :for="t.name" class="w-full cursor-pointer">
                <div class="w-full flex">
                  <div class="flex-shrink mr-2">
                    <input
                      type="checkbox"
                      v-model="t.selected"
                      :name="t.name"
                      :id="t.name"
                    />
                  </div>
                  <div class="flex-grow">{{ t.name }}</div>
                  <div class="flex-shrink">
                    <span
                      v-if="!t.winningJob"
                      class="rounded-full text-xs bg-secondary text-white px-3 py-1"
                      >Removing Job</span
                    >
                    <span
                      v-if="t.winningJob"
                      class="rounded-full text-xs bg-secondary text-white px-3 py-1"
                      >Adding Job</span
                    >
                  </div>
                </div>
              </label>
            </div>

            <div
              @click="sendChangeNotification()"
              class="w-full h-12 mt-4 bg-primary hover:bg-secondary text-white rounded cursor-pointer flex items-center justify-center"
            >
              Send Notifications
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="smsModal"
      class="w-full z-50 h-screen fixed top-0 left-0 bg-secondary bg-opacity-50"
    >
      <div class="w-full h-full relative flex items-center justify-center">
        <div
          class="w-96 relative py-20 px-10 rounded-lg p-4 bg-white shadow-lg"
        >
          <div class="absolute top-0 right-0 m-2">
            <i
              @click="(showTesters = false), (smsModal = false)"
              class="fa-solid fa-xmark cursor-pointer text-secondary hover:text-primary"
            ></i>
          </div>
          Write a message to send
          <!-- {{ smsTester.testerPhone }} -->
          <div class="w-full flex flex-wrap justify-center space-x-2 mt-4">
            <textarea
              class="w-full h-32 rounded bg-gray-100 p-4 text-gray-500 text-sm my-2"
              v-model="smsBox"
              cols="30"
              rows="10"
            ></textarea>
            <div class="flex-grow">
              <div
                @click="sendSms()"
                class="h-14 bg-primary px-4 w-full cursor-pointer hover:bg-gray-900 text-white rounded flex items-center justify-center"
              >
                Complete Job
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <table class="table-auto overflow-x-scroll bg-gray-100 w-full">
      <thead @mouseout="columnHover = -1">
        <tr>
          <th
            @mouseover="columnHover = hIndex"
            v-for="(h, hIndex) in columnDefs"
            :key="'col' + hIndex"
            :class="[
              currentlySortedBy == hIndex
                ? 'bg-primary text-white font-bold'
                : 'bg-secondary text-gray-400',
            ]"
            class="relative border-r border-gray-600 cursor-pointer hover:bg-gray-900 font-normal h-10 text-sm"
          >
            <div
              @click="sortCol(hIndex)"
              class="w-full h-full flex items-center justify-center flex space-x-1"
            >
              <span>{{ h.field }}</span>
              <i
                @click="h.openFilter = !h.openFilter"
                class="fa-solid fa-filter text-xs"
                v-if="h.filter"
              ></i>
            </div>

            <!-- Filter Panel -->
            <div
              v-if="h.openFilter"
              class="absolute top-0 left-0 p-4 mt-14 w-56 bg-white rounded-xl shadow-xl"
            >
              <div class="w-full text-left text-gray-400 relative">
                <div
                  class="absolute top-0 right-0 hover:text-primary"
                  @click="h.openFilter = !h.openFilter"
                >
                  x
                </div>
                <h2 class="text-secondary text-sm">Filter by {{ h.filter }}</h2>
                <div
                  class="w-full flex flex-wrap mt-2"
                  v-if="h.filter == 'select'"
                >
                  <div
                    class="w-full flex flex-wrap space-x-2 cursor-pointer"
                    v-for="(s, sIndex) in h.filterValueArray"
                    :key="'selectFilter' + sIndex"
                  >
                    <div class="flex-shrink order-1">
                      <input
                        @change="filterColumn($event, s, hIndex)"
                        v-model="s.visible"
                        type="checkbox"
                        class="cursor-pointer"
                        name="select"
                        :id="'sIndex' + sIndex"
                      />
                    </div>
                    <div class="flex-grow order-2">
                      <label :for="'sIndex' + sIndex" class="cursor-pointer">{{
                        s.value
                      }}</label>
                    </div>
                  </div>
                  <div
                    @click="h.openFilter = !h.openFilter"
                    class="w-full mt-1 text-right text-xs text-primary"
                  >
                    Close
                  </div>
                </div>
              </div>
            </div>
          </th>

          <th
            v-if="currentUser.role == 'admin'"
            class="bg-secondary text-gray-400 w-56 relative border-r border-gray-600 cursor-pointer hover:bg-gray-900 font-normal h-10 text-sm"
          >
            Notes
          </th>
          <th
            v-if="currentUser.role == 'admin'"
            @click="showPublishAll = true"
            class="bg-primary cursor-pointer hover:bg-secondary text-white"
          >
            Publish
          </th>
          <th
            v-if="currentUser.role == 'contractor'"
            class="text-gray-300 bg-secondary text-xs"
          >
            Complete
          </th>
        </tr>
      </thead>
      <tbody v-for="(i, index) in rowData" :key="index">
        <tr
          v-if="i.visible"
          :class="[
            i.status == 'ON-CALL' ? 'bg-yellow-100' : '',
            i.status == 'COMPLETED' ? 'bg-green-600 bg-opacity-25' : '',
            i.status == 'CANCELLED' ? 'bg-red-600 bg-opacity-25' : '',
          ]"
          class="cursor-pointer border-b border-gray-400"
        >
          <td
            v-for="(b, bIndex) in columnDefs"
            :key="'bIndex' + bIndex"
            :class="[
              columnHover == bIndex ? 'bg-black bg-opacity-10' : '',
              b.width,
            ]"
          >
            <flat-pickr
              v-if="b.field == 'time'"
              class="h-14 cursor-pointer w-16 text-xs text-gray-600 text-center bg-gray-200"
              :config="config"
              v-model="i.time"
              @on-close="handleChange(i.time, i.id, $event)"
            ></flat-pickr>

            <!-- <datetime @close="saveDate(i.time, i.id)"
                      v-if="b.field == 'time'"
                      v-model="i.time"
                      type="time"
                      input-class="w-16 text-center h-12 bg-white px-3 text-sm"
                      class="theme-orange" format="HH:mm"
                    ></datetime> -->

            <div
              v-if="b.field != 'time'"
              :class="[b.field == 'status' ? jobStyles(i) : '', b.width]"
              class="truncate flex h-12 w-full flex-wrap px-2 items-center justify-center"
            >
              <div
                v-if="b.field != 'status'"
                @click="showJobEdit(b.field, i)"
                :class="b.field == 'tester' ? testerStyle(i) : ''"
              >
                <div :class="b.width ? b.width : 'w-full'">
                  <h2 class="text-xs truncate">{{ i[b.field] }}</h2>
                </div>
                <span v-if="b.field == 'tester' && !i[b.field]">No Tester</span>
              </div>

              <div v-if="b.field == 'tester' && i[b.field]">
                <div
                  @click="
                    ((showTesters = smsModal = true),
                    (smsBox = `Update to Job \n${i.projectName} \nStatus: #${i.status} \nClient: #${i.client} \nCustomer: #${i.customer}\nAddress: ${i.fullAddress}\Time: #${i.time}\nMeters: #${i.meters}\nRequirements: #${i.requirements}
                  `)),
                      (smsTester = i)
                  "
                  class="h-6 px-4 hover:bg-secondary bg-primary text-white text-xs rounded flex items-center justify-center"
                >
                  SMS TESTER
                </div>
              </div>

              <div v-if="b.field == 'status'">
                <select
                  @change="updateStatus(i)"
                  v-model="i.status"
                  class="text-xs outline-none bg-white bg-opacity-0 text-center w-full h-12"
                >
                  <option>FIRM</option>
                  <option>ON-CALL</option>
                  <option>COMMITTED</option>
                  <option>COMPLETED</option>
                  <option>CANCELLED</option>
                </select>
              </div>
            </div>
          </td>

          <td class="bg-white" v-if="currentUser.role == 'admin'">
            <input
              type="text"
              @keyup="updateNote(i)"
              v-model="i.notes"
              class="w-full h-12 bg-gray-100 px-3 text-sm"
            />
          </td>
          <td
            class="bg-white text-gray-900 w-24"
            v-if="currentUser.role == 'admin'"
          >
            <select
              @change="updateJob(index)"
              v-model="i.published"
              :class="
                i.published
                  ? 'bg-green-100 text-green-600'
                  : 'bg-red-100 text-red-600'
              "
              class="text-xs outline-none text-center w-full h-12"
            >
              <option :value="true">Published</option>
              <option :value="false">Unpublished</option>
            </select>
          </td>
          <td
            @click="signOff = index"
            v-if="currentUser.role == 'contractor'"
            class="text-sm font-bold px-6 h-full bg-primary text-white cursor-pointer hover:bg-secondary"
          >
            Sign Off
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-if="rowData.length < 1"
      class="h-screen text-gray-400 text-lg w-full pt-40 flex justify-center"
    >
      Sorry, no jobs found on this date
    </div>
  </div>
</template>

<script>
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { postFire, setFire } from "../../firestore/utils";
import { getTimeStamp, db } from "../../firebase";
import { bus } from "../../main";

import { Parser } from "json2csv";

import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

let unsubscribe;

export default {
  props: ["date", "search", "triggerExport"],
  data() {
    return {
      config: {
        enableTime: true,
        altFormat: "G:iK",
        altInput: true,
        allowInput: false,
      },
      showNotificationPopup: [],
      showTesters: false,
      selectedJob: null,
      testers: [],
      smsTester: null,
      smsModal: false,
      smsBox: "",
      signOff: -1,
      showPublishAll: false,
      currentlySortedBy: null,
      columnHover: -1,
      columnDefs: [
        {
          field: "date",
          width: "w-16",
          filterField: "date",
          openFilter: false,
          filterValue: "",
          filterValueArray: [],
          sortDirection: "asc",
        },
        {
          field: "time",
          width: "w-16",
          filterField: "time",
          openFilter: false,
          filterValue: "",
          filterValueArray: [],
          sortDirection: "asc",
        },
        // {
        //   field: "poNumber",
        //   width: "w-20",
        //   filterField: "poNumber",
        //   openFilter: false,
        //   filterValue: "",
        //   filterValueArray: [],
        //   sortDirection: "asc",
        // },
        {
          field: "client",
          width: "w-10",
          filterField: "client",
          openFilter: false,
          filterValue: "",
          filterValueArray: [],
          sortDirection: "asc",
        },
        // {
        //   field: "projectId",
        //   width: "w-12",
        //   filterField: "projectId",
        //   filter: "",
        //   openFilter: false,
        //   filterValue: "",
        //   filterValueArray: [],
        //   sortDirection: "asc",
        // },
        {
          field: "projectName",
          width: "w-32",
          filterField: "projectName",
          filter: "",
          openFilter: false,
          filterValue: "",
          filterValueArray: [],
          sortDirection: "asc",
        },
        {
          field: "customer",
          width: "w-20",
          filterField: "company",
          filter: "select",
          openFilter: false,
          filterValue: "",
          filterValueArray: [],
          sortDirection: "asc",
        },
        {
          field: "region",
          width: "w-20",
          filterField: "region",
          filter: "select",
          openFilter: false,
          filterValue: "",
          filterValueArray: [],
          sortDirection: "asc",
        },
        {
          field: "fullAddress",
          filterField: "fullAddress",
          width: "w-56",
          openFilter: false,
          filterValue: "",
          filterValueArray: [],
          sortDirection: "asc",
        },
        // {
        //   field: "suburb",
        //   filterField: "address.locality",
        //   width: "w-32",
        //   filter: "select",
        //   openFilter: false,
        //   filterValue: "",
        //   filterValueArray: [],
        //   sortDirection: "asc",
        // },
        {
          field: "mixCode",
          width: "w-10",
          filterField: "mixCode",
          openFilter: false,
          filterValue: "",
          filterValueArray: [],
          sortDirection: "asc",
        },
        {
          field: "meters",
          width: "w-14",
          filterField: "meters",
          openFilter: false,
          filterValue: "",
          filterValueArray: [],
          sortDirection: "asc",
        },

        {
          field: "requirements",
          filterField: "requirements",
          openFilter: false,
          width: "w-40",
          filterValue: "",
          filterValueArray: [],
          sortDirection: "asc",
        },
        {
          field: "tester",
          filterField: "contractor.name",
          width: "w-20",
          filter: "select",
          openFilter: false,
          filterValue: "",
          filterValueArray: [],
          sortDirection: "asc",
        },
        {
          field: "status",
          filterField: "status",
          width: "w-28",
          filter: "select",
          openFilter: false,
          filterValue: "",
          filterValueArray: [],
          sortDirection: "asc",
        },
      ],
      rowData: [],
    };
  },
  computed: {
    visibleRows() {
      return this.rowData.filter((i) => i.visible);
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  watch: {
    triggerExport() {
      if (this.triggerExport) this.exportToCSV();
    },
    columnDefs: {
      handler() {
        // this.filterResults();
      },
      deep: true,
    },
    search() {
      this.filterResults();
    },
    date: {
      immediate: false,
      handler(newVal, oldVal) {
        // alert(this.date)
        this.getItems();
      },
    },
    // prevDate: {
    //   immediate: false,
    //   handler(newVal, oldVal) {
    //     // alert(this.date)
    //     this.getItems();
    //   },
    // },
    rowData: {
      handler() {
        bus.$emit("jobs", this.rowData);
      },
      deep: true,
    },
  },
  methods: {
    filterColumn(event, col, hIndex) {
      const columnName = col.value;
      let arr = [];
      const obj = this.columnDefs[hIndex].filterValueArray;
      // Loop through the columns unique values using param filterValueArray
      for (let i = 0; i < obj.length; i++) {
        // any object that is NOT selected push to an array
        if (!obj[i].visible) arr.push(obj[i].value);
      }

      // If we have nothing selected
      // In this case we should have arr having all unselected fields
      let clear = false;
      if (arr.length == this.columnDefs[hIndex].filterValueArray.length)
        clear = true;

      // Now loop through all rowData and hide any rows where arr includes the rowData['columnName']
      for (let i = 0; i < this.rowData.length; i++) {
        if (clear) {
          this.rowData[i].visible = true;
        } else {
          if (arr.includes(this.rowData[i][this.columnDefs[hIndex].field]))
            this.rowData[i].visible = false;
          else this.rowData[i].visible = true;
        }

        //  if this.rowData[i][this.columnDefs[hIndex].field] is blank
        // make visible false
        // unless Unassigned

        if (!this.rowData[i][this.columnDefs[hIndex].field]) {
          this.rowData[i].visible = false;

          if (columnName == "Unassigned") this.rowData[i].visible = true;
        }
      }
    },
    sendChangeNotification() {
      const payload = {
        client: this.selectedJob.client,
        testers: this.showNotificationPopup,
        jobName: this.selectedJob.projectName,
        jobAddress: this.selectedJob.fullAddress,
        customer: this.selectedJob.company,
        region: this.selectedJob.region,
        jobTime: this.$moment(this.selectedJob.time).format("HH:mmA"),
        jobDate: this.selectedJob.date,
        meters: this.selectedJob.meters,
        requirements: this.selectedJob.requirements,
        mixCode: this.selectedJob.mixCode,
        clientName: this.selectedJob.contact,
        clientPhone: this.selectedJob.phone,
        clientEmail: this.selectedJob.email,
        status: this.selectedJob.status,
      };
      console.log("payload", payload);
      this.axios
        .post(
          "https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/notify/change-job",
          payload
        )
        .then((response) => {
          this.showNotificationPopup = [];
        })
        .catch((e) => {
          console.log("Error sending notifications", e);
        });

      // Close Modals
      this.showNotificationPopup = [];
    },
    async handleChange(time, id, selectedDates) {
      console.log("time", time);
      console.log("selectedDates", selectedDates);
      await this.saveDate(selectedDates[0], id);
    },
    async saveDate(time, id) {
      console.log("time", time);
      await setFire({
        collection: "jobs",
        doc: id,
        body: {
          date: new Date(time),
        },
      });
      // Sort times
      this.rowData.sort((a, b) => new Date(a.time) - new Date(b.time));

      // this.getItems()
    },
    async updateNote(job) {
      await setFire({
        collection: "jobs",
        doc: job.id,
        body: {
          notes: job.notes,
        },
      });
    },
    async updateStatus(job) {
      await setFire({
        collection: "jobs",
        doc: job.id,
        body: {
          status: job.status,
        },
      });
    },
    async removeTester(selectedJob){
      await setFire({
        collection: "jobs",
        doc: selectedJob.id,
        body: {
          contractor: null,
        },
      });

      this.showTesters = false;
    },
    async updateTester(tester, index, selectedJob) {
      console.log("selectedJob", selectedJob);
      if (selectedJob.tester) {
        // In this case we are changing a tester not adding a new one
        // So we want to show a popup

        this.showNotificationPopup = [
          // Winning job
          {
            name: tester.name,
            email: tester.email,
            phone: tester.phone,
            winningJob: true,
            selected: true,
          },
          // Loosing job
          {
            name: selectedJob.tester,
            email: selectedJob.testerEmail,
            phone: selectedJob.testerPhone,
            winningJob: false,
            selected: true,
          },
        ];
      }

      await setFire({
        collection: "jobs",
        doc: selectedJob.id,
        body: {
          contractor: tester,
        },
      });
      // this.getItems();
      // Close modals
      this.showTesters = false;
    },
    rowSpan(field) {
      let val = 0; // or 2
      if (field == "status") val = 2;
      return val;
    },
    async cancelJob() {
      await setFire({
        collection: "jobs",
        doc: this.rowData[this.signOff].id,
        body: {
          status: "CANCELLED",
        },
      });
      this.getItems();
      this.signOff = -1;
    },
    async completeJob() {
      await setFire({
        collection: "jobs",
        doc: this.rowData[this.signOff].id,
        body: {
          status: "COMPLETED",
        },
      });
      this.getItems();
      this.signOff = -1;
    },
    exportToCSV() {
      // Convert object array to CSV format
      const json2csvParser = new Parser();

      let j = this.visibleRows;
      for (let i = 0; i < j.length; i++) {
        j[i].time = this.$moment(j[i].time).format("HH:mmA");
      }

      const csvData = json2csvParser.parse(j);

      // Create a Blob object with CSV data
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a temporary anchor element
      const anchor = document.createElement("a");
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = "data.csv";

      // Programmatically click the anchor element to trigger the download
      anchor.click();

      // Clean up the temporary anchor element
      window.URL.revokeObjectURL(anchor.href);
      anchor.remove();
      bus.$emit("triggerExport", false);
    },
    async publishAll() {
      console.log("publishing all", this.visibleRows);
      if (this.currentUser.role == "admin") {
        console.log("finding emails or phone");
        let phoneNumbers = [];
        let emails = [];
        for (let i = 0; i < this.visibleRows.length; i++) {
          console.log("this.rowData[i].id", this.visibleRows[i].id);
          // Get all tester phone numbers make sure they are uniq
          // don't want to send multiple sms
          if (
            this.visibleRows[i].tester &&
            this.visibleRows[i].testerPhone &&
            !phoneNumbers.includes(this.visibleRows[i].testerPhone)
          ) {
            phoneNumbers.push(this.visibleRows[i].testerPhone);
          }
          // Email
          // send one email with all jobs
          console.log("this.visibleRows[i]", this.visibleRows[i]);
          console.log(
            "this.visibleRows[i].testerEmail",
            this.visibleRows[i].testerEmail
          );
          if (this.visibleRows[i].tester && this.visibleRows[i].testerEmail) {
            let _index;
            console.log(
              "this.visibleRows[i].testerEmail",
              this.visibleRows[i].testerEmail
            );
            // See if email object exists
            const _e = emails.filter((e) => {
              e.testerEmail == this.visibleRows[i].testerEmail;
            });

            console.log("_e", _e);
            // If it does then find the index and add job to the jobs array
            // else create a new object
            const _job = {
              projectId: this.visibleRows[i].projectId,
              projectName: this.visibleRows[i].projectName,
              date: this.visibleRows[i].date,
              time: this.$moment(this.visibleRows[i].time).format("HH:mmA"),
              client: this.visibleRows[i].client,
              company: this.visibleRows[i].company,
              customerId: this.visibleRows[i].customerId,
              customer: this.visibleRows[i].company,
              region: this.visibleRows[i].region,
              fullAddress: this.visibleRows[i].fullAddress,
              address: this.visibleRows[i].address,
              suburb: this.visibleRows[i].suburb,
              mixCode: this.visibleRows[i].mixCode,
              meters: this.visibleRows[i].meters,
              id: this.visibleRows[i].id,
              status: this.visibleRows[i].status,
              requirements: this.visibleRows[i].requirements,
              poNumber: this.visibleRows[i].poNumber,
              email: this.visibleRows[i].email,
              phone: this.visibleRows[i].phone,
              contact: this.visibleRows[i].contact,
              published: this.visibleRows[i].published,
            };
            if (_e.length > 0) {
              _index = emails.indexOf(_e);
              emails[_index].jobs.push(_job);
            } else {
              const obj = {
                email: this.visibleRows[i].testerEmail,
                jobs: [_job],
              };
              emails.push(obj);
            }
          }

          await setFire({
            collection: "jobs",
            doc: this.visibleRows[i].id,
            body: {
              published: true,
            },
          });
        }

        console.log("phoneNumbers", phoneNumbers);
        // Now loop phonenumbers and send message
        for (let i = 0; i < phoneNumbers.length; i++) {
          await postFire({
            collection: "messages",
            body: {
              to: phoneNumbers[i],
              message: `KCT Job board has been published for tomorrow - please sign in to see your jobs.`,
              sent: false,
              twilio: null,
              twilioSid: "",
            },
          });
        }
        // Loop through and send emails
        console.log("emails-", emails);
        // Consolodate array so all jobs appear in just one email object
        const consolidatedArray = emails.reduce((acc, current) => {
          const existingEntry = acc.find(
            (entry) => entry.email === current.email
          );

          if (existingEntry) {
            existingEntry.jobs = existingEntry.jobs.concat(current.jobs);
          } else {
            acc.push({ email: current.email, jobs: current.jobs });
          }

          return acc;
        }, []);

        for (let i = 0; i < consolidatedArray.length; i++) {
          console.log("consolidatedArray[i]", consolidatedArray[i]);

          this.axios.post(
            "https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/notify/publish-job",
            { jobs: consolidatedArray[i] }
          );
        }

        this.getItems();
        this.showPublishAll = false;
      } else {
        alert("you do not have access to publish jobs");
      }
    },
    showJobEdit(field, obj) {
      if (field != "tester") {
        // bus.$emit("updateJob", obj);
        this.$router.push({ name: "Jobs", query: { jobId: obj.id } });
      } else {
        this.showTesters = true;
        this.selectedJob = obj;
      }
    },
    async sendSms() {
      await postFire({
        collection: "messages",
        body: {
          to: this.smsTester.testerPhone,
          message: this.smsBox,
          sent: false,
          twilio: null,
          twilioSid: "",
        },
      });

      this.showTesters = false;
      this.smsModal = false;
    },
    async updateJob(index) {
      await setFire({
        collection: "jobs",
        doc: this.rowData[index].id,
        body: {
          published: this.rowData[index].published,
        },
      });

      if (this.rowData[index].published) {
        // Send notification to job published
        const _job = {
          projectId: this.rowData[index].projectId,
          projectName: this.rowData[index].projectName,
          date: this.rowData[index].date,
          time: this.$moment(this.rowData[index].time).format("HH:mmA"),
          client: this.rowData[index].client,
          company: this.rowData[index].company,
          customerId: this.rowData[index].customerId,
          customer: this.rowData[index].company,
          region: this.rowData[index].region,
          fullAddress: this.rowData[index].fullAddress,
          address: this.rowData[index].address,
          suburb: this.rowData[index].suburb,
          mixCode: this.rowData[index].mixCode,
          meters: this.rowData[index].meters,
          id: this.rowData[index].id,
          status: this.rowData[index].status,
          requirements: this.rowData[index].requirements,
          poNumber: this.rowData[index].poNumber,
          email: this.rowData[index].email,
          phone: this.rowData[index].phone,
          contact: this.rowData[index].contact,
          published: this.rowData[index].published,
        };

        const arr = {
          email: this.rowData[index].testerEmail,
          jobs: [_job],
        };

        this.axios.post(
          "https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/notify/publish-job",
          {
            jobs: arr,
          }
        );
        // Send sms
        await postFire({
          collection: "messages",
          body: {
            to: this.rowData[index].testerPhone,
            message: `A job has been published to your board - ${_job.projectName}. Please check the dashboard for details.`,
            sent: false,
            twilio: null,
            twilioSid: "",
          },
        });
      }
    },
    sortCol(colIndex) {
      this.currentlySortedBy = colIndex;
      if (this.columnDefs[colIndex].sortDirection == "asc")
        this.columnDefs[colIndex].sortDirection = "des";
      else this.columnDefs[colIndex].sortDirection = "asc";

      // this.rowData = sortJsonArray(
      //   this.rowData,
      //   this.columnDefs[colIndex].field,
      //   this.columnDefs[colIndex].sortDirection
      // );
    },
    getUniqs(field, _arr) {
      let arr = [];
      for (let i = 0; i < _arr.length; i++) {
        console.log("uniqArr", _arr[i]);
        if (!arr.includes(_arr[i])) {
          arr.push(_arr[i]);
        }
      }

      return arr;
    },
    getVisible(items) {
      console.log("items", items);
      const a = items.filter((i) => i.visible);
      console.log("a", a);
      return a;
    },
    filterResults() {
      const prevDate = this.date.split("&")[0];
      const date = this.date.split("&")[1];

      const url =
        "https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/search";
      const d = this.$moment(prevDate).startOf("day").format("x");
      const ld = this.$moment(date).endOf("day").format("x");
      let queryStr = `date:${d} TO ${ld}`;
      this.columnDefs.forEach((c) => {
        this.getVisible(c.filterValueArray).forEach((f, index) => {
          let andOr = "";
          if (queryStr.split("").length > 0) andOr = " AND";
          if (index > 0) andOr = " OR";

          const str = `${andOr} ${c.filterField}:'${f.value}'`;

          if (f.visible) queryStr = queryStr.concat(str);
        });
      });

      this.axios
        .get(url, {
          params: {
            indexName: "jobs",
            filter: queryStr,
            search: this.search,
          },
        })
        .then(async (response) => {
          await this.formatJob(response.data, true);
        })
        .catch((e) => {
          console.log("Error algolia", e);
        });
    },

    showWorkCard(i) {
      bus.$emit("showWorkCardFunc", i);
    },
    jobStyles(job) {
      if (job.status == "ON-CALL")
        return "bgyellowstripes text-white bg-yellow-600";
      if (job.status == "FIRM") return "bg-green-200 text-green-600";
      if (job.status == "COMMITTED") return "text-white bg-blue-600";
      if (job.status == "COMPLETED")
        return "bggreenstripes text-white bg-green-600";
      if (job.status == "CANCELLED") return " text-white bg-red-800";
    },
    testerStyle(job) {
      if (!job.tester)
        return "rounded-full px-3 text-xs py-1 bgredstripes text-white bg-red-900";
    },
    async getItems() {
      this.rowData = [];
      if (this.date) {
        const prevDate = this.date.split("&")[0];
        const date = this.date.split("&")[1];
        const start = this.$moment(prevDate).startOf("day");
        const end = this.$moment(date).endOf("day");

        let payload = {
          collection: "jobs",
          dateFrom: getTimeStamp(new Date(start)),
          dateTo: getTimeStamp(new Date(end)),
          contractor: null,
        };

        if (this.currentUser.role != "admin")
          payload.contractor = this.currentUser.id;

        let q;
        if (payload.contractor) {
          // Show result only where contactor is equal to payload.tester
          // also testers can only see published jobs
          q = query(
            collection(db, "jobs"),
            where("date", ">", payload.dateFrom),
            where("date", "<", payload.dateTo),
            where("contractor.id", "==", payload.contractor),
            where("published", "==", true),
            orderBy("date")
          );
        } else {
          q = query(
            collection(db, "jobs"),
            where("date", ">", payload.dateFrom),
            where("date", "<", payload.dateTo),
            orderBy("date")
          );
        }

        unsubscribe = onSnapshot(q, (snapshot) => {
          let _jobs = [];
          snapshot.docChanges().forEach(async (change) => {
            if (change.type === "added") {
              const formatJob = await this.formatJob(
                [{ ...change.doc.data(), id: change.doc.id }],
                false,
                true
              );
              this.rowData.push(formatJob);
              this.rowData.sort((a, b) => new Date(a.time) - new Date(b.time));
              this.filterObjects();
            }
            if (change.type === "modified") {
              // Find the index of the job in the _jobs array by its ID.
              const index = this.rowData.findIndex(
                (job) => job.id === change.doc.id
              );
              // If the job is found in the array, update it.
              if (index !== -1) {
                console.log("modified starting");
                const formatJob = await this.formatJob(
                  [{ ...change.doc.data(), id: change.doc.id }],
                  false,
                  true
                );
                console.log("second stage starting", formatJob);

                // Update the specific item using Vue's reactivity helper methods
                // this.$set(this.rowData, index, formatJob); // using $set
                // OR
                this.rowData.splice(index, 1, formatJob); // using splice
                this.filterObjects();

                console.log("final stage index", this.rowData[index]);
              }
            }
            if (change.type === "removed") {
              const index = this.rowData.findIndex(
                (job) => job.id === change.doc.id
              );
              console.log("Removed job: ", change.doc.data());
              if (index !== -1) {
                this.rowData.splice(index, 1);
                this.filterObjects();
              }
            }
          });
        });
      }

      // console.log(":_jobs", _jobs)
    },
    filterObjects() {
      this.columnDefs.forEach((c, indexOfO) => {
        const fields = this.rowData
          .filter((obj) => obj["filter"] !== "")
          .map((obj) => obj[c.field]);
        console.log("fields", fields);
        const uniqValues = this.getUniqs(c.field, fields);
        let payload = [
          {
            value: "Unassigned",
            visible: false,
          },
        ];

        console.log("uniqValues", uniqValues);
        uniqValues.forEach((u) => {
          if (u) {
            console.log("u", u);
            payload.push({
              value: u,
              visible: false,
            });
          }
        });

        console.log(":final araa", payload);

        this.columnDefs[indexOfO].filterValueArray = payload;
      });
    },
    formatJob(arr, skipUniqs, single) {
      return new Promise((res, rej) => {
        let a = [];
        for (let i = 0; i < arr.length; i++) {
          let _formatAddress = arr[i].formattedAddress || "";

          let _date = "";
          if (arr[i].date.seconds) _date = new Date(arr[i].date.seconds * 1000);
          else _date = new Date(arr[i].date);

          let payload = {
            visible: true,
            projectId: arr[i].projectId,
            projectName: arr[i].projectName,
            date: this.$moment(_date).format("DD MMM"),
            time: new Date(_date),
            client: arr[i].client,
            customerId: arr[i].customerId,
            customer: arr[i].company,
            region: arr[i].region,
            company: arr[i].company,
            fullAddress: _formatAddress || "",
            address: arr[i].address || "",
            geopoint: arr[i].geopoint || "",
            geohash: arr[i].geohash || "",
            mixCode: arr[i].mixCode,
            meters: arr[i].meters,
            id: arr[i].id,
            status: arr[i].status,
            requirements: arr[i].requirements,
            pourLocation: arr[i].pourLocation,
          notesForTester: arr[i].notesForTester,
            poNumber: arr[i].poNumber,
            tester: "",
            email: arr[i].email,
            phone: arr[i].phone,
            contact: arr[i].contact,
            testerPhone: "",
            testerEmail: "",
            testerNotes: arr[i].testerNotes,
            notes: arr[i].notes,
            published: arr[i].published,
          };

          // Add tester if it exists
          if (arr[i].contractor) {
            payload.tester = arr[i].contractor.name;
            payload.testerPhone = arr[i].contractor.phone;
            payload.testerEmail = arr[i].contractor.email;
          }
          a.push(payload);
        }

        if (!single) {
          this.rowData = a;
          res();
        } else res(a[0]);
      });
    },
    async getTesters() {
      const usersCollection = collection(db, "users");

      const q = query(usersCollection, where("active", "==", true));

      await getDocs(q)
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("No documents found matching the criteria");
            return;
          }

          querySnapshot.forEach((doc) => {
            this.testers.push({ ...doc.data(), id: doc.id });
          });
        })
        .catch((error) => {
          console.log("Error getting documents:", error);
        });
    },
  },
  beforeDestroy() {
    if (unsubscribe) {
      unsubscribe();
    }
  },
  created() {
    // alert(this.date)
    this.getItems();
    this.getTesters();
  },
  components: {
    Datetime,
    flatPickr,
  },
};
</script>
